$track-color: rgba(#a4b0b3, 0.3) !default;

$thumb-color: linear-gradient(269deg, #2d6979, #3c8689) !default;
$thumb-radius: 15px !default;
$thumb-height: 20px !default;
$thumb-width: 20px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0) !default;
$thumb-border-width: 0px !default;
$thumb-border-color: #fff !default;

$track-width: 100% !default;
$track-height: 4px !default;
$track-shadow-size: 0px !default;
$track-shadow-blur: 0px !default;
$track-shadow-color: rgba(0, 0, 0, 0) !default;
$track-border-width: 0px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 0px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin track {
	cursor: default;
	height: $track-height;
	transition: all 0.2s ease;
	width: $track-width;
	padding: 0px 2px;
	margin: 0 1px;
	border-radius: $track-radius;
}

@mixin thumb {
	box-shadow: none;
	background: $thumb-color;
	//border: $thumb-border-width solid $thumb-border-color;
	border-radius: $thumb-radius;
	box-sizing: border-box;
	cursor: pointer;
	height: 14px;
	//clip-path: polygon(50% 0%, 100% 35%, 100% 100%, 0 100%, 0 35%);
	position: relative;
	transform: translate(0, -5px);
	//	border: math.div($thumb-height, 2) solid transparent;
	//border-bottom: math.div($thumb-height, 2) solid #5e86c3;
	width: 14px;
}

input[type="range"] {
	-webkit-appearance: none;
	background: transparent;
	margin: 0;
	width: $track-width;
	&::-moz-focus-outer {
		border: 0;
	}

	&:focus {
		outline: 0;
		&::-webkit-slider-thumb {
			border: none;
			box-shadow: none;
			//background: none;
		}
		&::-webkit-slider-runnable-track {
			background: lighten($track-color, $contrast);
		}

		&::-ms-fill-lower {
			background: $track-color;
		}

		&::-ms-fill-upper {
			background: lighten($track-color, $contrast);
		}
	}

	&::-webkit-slider-runnable-track {
		@include track;
		background: $track-color;
		border: $track-border-width solid $track-border-color;
		border-radius: $track-radius;
	}

	&::-webkit-slider-thumb {
		@include thumb;
		-webkit-appearance: none;
		margin-top: 0;
	}

	&::-moz-range-track {
		@include track;
		background: $track-color;
		border: $track-border-width solid $track-border-color;
		border-radius: $track-radius;
		height: math.div($track-height, 2);
	}

	&::-moz-range-thumb {
		@include thumb;
	}

	&::-ms-track {
		@include track;
		background: transparent;
		border-color: transparent;
		border-width: math.div($thumb-height, 2) 0;
		color: transparent;
	}

	&::-ms-fill-lower {
		background: $ie-bottom-track-color;
		border: $track-border-width solid $track-border-color;
		border-radius: $track-radius;
	}

	&::-ms-fill-upper {
		background: $track-color;
		border: $track-border-width solid $track-border-color;
		border-radius: $track-radius;
	}

	&::-ms-thumb {
		@include thumb;
		margin-top: math.div($track-height, 4);
	}

	&:disabled {
		opacity: 0.5;
		filter: grayscale(100%);
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb,
		&::-webkit-slider-runnable-track,
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			cursor: not-allowed;
		}
	}
}
