.deposit-process-wrap,
.deposit-cancel-wrap {
	display: inline-flex;
	position: relative;
	transition: all 0.4s ease-in-out;
	position: relative;
	z-index: 10;
	border-radius: 13px;
	input {
		transition: all 0.3s ease-in-out;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0 44px;
		height: 55px;
		border-radius: 50px;
		text-align: center;
		text-decoration: none;
		font-size: 18px;
		transition: all 0.4s;
		letter-spacing: 0.375px;
		font-weight: 700;
		color: #fff;
		position: relative;
		white-space: nowrap;
		border: none;
		cursor: pointer;
		background: transparent;
	}
}
.deposit-process-wrap {
	color: #fff;
	border: 1px solid #5fb5ad;
	background: linear-gradient(#4fa797, #285c6e 50%, #4fa797 100%);
	background-size: auto 200%;
	background-position: center top;
	box-shadow:
		0px -4px 9px 0px #499b91 inset,
		0px 10px 15px -8px rgba(#1e4a47, 0.2);
	&:hover,
	&:focus {
		color: #fff;
		background-position: center bottom;
	}
}
.deposit-cancel-wrap {
	input {
		color: #634a0b;
		&:hover {
			color: #634a0b;
		}
	}
	border: 1px solid #fff;
	background: linear-gradient(180deg, #ffda41 0%, #ff993a 50%, #ffda41 100%);
	background-size: auto 200%;
	background-position: center top;
	box-shadow:
		0px -4px 9px 0px #ffd33f inset,
		0px 10px 15px -5px rgba(#9d7819, 0.25);
	&:hover,
	&:focus {
		background-position: center bottom;
	}
}

.deposit-confirm {
	position: relative;
	text-align: left;
	img {
		border-radius: 10px;
		height: 250px;
		margin-bottom: 15px;
		position: relative;
	}
	&__info {
		display: flex;
		align-items: center;

		i {
			margin-right: 12px;
			font-size: 20px;
			color: #ee2f4f;
		}
		border-radius: 10px;
		padding: 15px 20px;
		border: 1px solid rgba(#ee2f4f, 0.15);
		background: radial-gradient(ellipse at top center, rgba(#ee2f4f, 0.08) 0, rgba(#ee2f4f, 0) 50%), rgba(#ee2f4f, 0.1);
		p {
			font-size: 15px;
			color: #a1666f;
		}
	}

	.btn-copy {
		position: absolute;
		height: 52px;
		width: 52px;
		top: 0px;
		left: 0;
		display: flex;
		color: darken(#d1e2ef, 15%);
		font-size: 17px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		&:hover {
			color: $blue;
		}
	}

	.copy-link {
		position: relative;
	}

	> div:not(#placeforstatus) {
		b {
			font-weight: 500;
			margin-top: 10px;
			text-align: left;
			color: $dark;
			width: 100%;
			font-size: 18px;
			height: 52px;
			background: #fff;
			padding: 0 20px 0 50px;
			border: 1px solid #d1e2ef;
			border-radius: 15px;
			display: flex;
			align-items: center;
			white-space: nowrap;
			flex-wrap: wrap;
			clear: both;
			margin-bottom: 15px;
			> div {
				display: inline-flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

	.crypto-adress {
		font-size: 15px;
		font-family: $font;
		text-align: left;
		margin-top: 10px;
		color: #2b2c2e;
		width: 100%;
		height: 52px;
		background: #fff;
		padding: 0 20px 0 50px;
		border: 1px solid #d1e2ef;
		border-radius: 15px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		flex-wrap: wrap;
		clear: both;
		font-style: normal;
		abbr {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		> div {
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}

	.payment_status {
		display: flex;
		justify-content: space-between;
		b {
			font-weight: 400;
		}
		.status_text {
			font-size: 16px;
			font-weight: bold;
			color: $dark;
		}
	}
}
