%massage-list {
	position: relative;
	z-index: 999;
	display: flex;
	align-items: center;
	flex-direction: column;
	transition: all 0.3s ease-in-out;
	margin-bottom: 20px;
	> li {
		width: 100%;
		padding: 22px 25px;
		border-radius: 0px;
		margin: 0px;
		margin-bottom: 10px;
		position: relative;
		z-index: 1001;
		font-family: $font1;
		font-size: 15px;
		border-radius: 10px;
		backdrop-filter: blur(13px);
		a {
			color: inherit;
			padding: 0 5px;
			text-decoration: underline;
		}
		b {
			font-weight: bold;
			display: block;
			padding: 0 5px;
			margin-bottom: 3px;
		}
		i {
			flex-shrink: 0;
			font-size: 25px;
			margin-right: 10px;
			opacity: 0.5;
		}
	}
}

ul.errors-list {
	@extend %massage-list;
	> li {
		color: $red;
		display: flex;
		align-items: center;
		background: radial-gradient(circle at left top, rgba($red, 0.2), rgba($red, 0) 20%), rgba($red, 0.03);
	}
}

ul.success-list {
	@extend %massage-list;
	> li {
		background: radial-gradient(circle at left top, rgba($green, 0.2), rgba($green, 0) 20%), rgba($green, 0.03);
		display: flex;
		align-items: center;
		color: $green;
	}
}
