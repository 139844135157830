body {
	font-family: $font;
	line-height: $line-height;
	font-weight: 400;
	font-size: $font-size;
	position: relative;
	letter-spacing: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
}

.wrapper {
	max-width: 1920px;
	position: relative;
	display: block;
	margin: 0 auto;
	//min-width: 375px;
	min-width: 1230px;
	min-height: 100%;
	overflow: hidden;
	padding: 0;
	&-account {
		overflow: visible;
	}
}
