.dashboard {
	position: relative;
	display: flex;
	min-height: 100vh;
	background: url(../images/page-about-bg.jpg) center top no-repeat;
	background-size: cover;
	&__logo {
		display: inline-flex;
	}

	&__menu {
		position: relative;
		padding: 33px 0;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			height: 4px;
			width: calc(872px + 40px + (100vw - 1230px) / 2);
			max-width: 1283px;
			background: #000;
			bottom: 0;
			left: -40px;
			background: url("../images/svg/line-devider-wide.svg") left top no-repeat;
			position: absolute;
		}
	}
	&__devider {
		position: relative;
		height: 4px;
		img {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	&__nav {
		flex-shrink: 0;
		display: flex;
		width: 100%;
		box-shadow: 0 0 40px rgba(#fff, 1);
		min-height: 100vh;
		padding: 30px 0px;
		flex-direction: column;
		position: sticky;
		top: 0px;
		z-index: 10;
		&:before {
			height: 100%;
			width: calc(100% + 375px);
			right: 0;
			top: 0;
			content: "";
			display: block;
			position: absolute;
			z-index: -1;
			border-radius: 0 15px 15px 0;
			background: url("../images/header-form.jpg") center top no-repeat;
			background-size: cover;
		}
	}
	&__top {
		display: flex;
		padding: 33px 0;
		align-items: center;
	}

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.avatar {
		padding: 5px;
		border: 1px solid #e6eeee;
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 10px 20px -15px #dae9ed;
		&__row {
			display: flex;
			align-items: center;
		}
		&__popover {
			font-size: 7px;
			height: 50px;
			cursor: pointer;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			box-shadow: 0 10px 20px -15px #dae9ed;
			border: 1px solid #e8efef;
			color: $text;
			transition: all 0.2s ease-in-out;
			&:hover {
				color: $dark;
			}
		}
		&__icon {
			height: 55px;
			width: 50px;
			overflow: hidden;
			border-radius: 10px;
			position: relative;
			margin-top: -4px;
			margin-left: 2px;
			&:before {
				height: 50px;
				background: url("../images/bg-user-icon.png") center center no-repeat;
				background-size: cover;
				position: absolute;
				z-index: 0;
				left: 0;
				content: "";
				bottom: 0;
				right: 0;
				width: 50px;
				border-radius: 10px;
			}
			img {
				bottom: 0;
				margin-left: 2px;
				position: absolute;
				width: 91%;
				z-index: 2;
			}
		}

		&__login {
			display: inline-flex;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 22px;
			font-style: normal;
			font-weight: 700;
			letter-spacing: -1.1px;
			padding-right: 3px;
			margin-top: -3px;
			margin-bottom: 2px;
			background-image: linear-gradient(270deg, #050505 78.11%, rgba(5, 5, 5, 0.27) 115.85%);
		}

		&__email {
			display: flex;
			line-height: 1;
			align-items: center;
			color: #929292;
			font-size: 16px;
			i {
				color: $green;
				margin-top: 2px;
			}
		}
	}

	.balance-box {
		background: url("../images/balance-bg.png") right bottom no-repeat;
		background-size: cover;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		padding: 40px 40px;
		&__label {
			margin-bottom: 10px;
			font-family: $font1;
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			letter-spacing: 0.35em;
		}
		&__dhs {
			border: 1px solid transparent;
			padding: 15px 17px;
			backdrop-filter: blur(7px);
			background: #ffffff75;
			border-radius: 15px;
			margin-bottom: 10px;
			b {
				-webkit-text-fill-color: transparent;
				background: linear-gradient(269deg, #2d6979, #3c8689);
				background-clip: text;
				-webkit-background-clip: text;
				font-family: $font1;
				font-size: 32px;
				font-weight: 900;
				letter-spacing: -0.8px;
				line-height: 90.2%;
			}
			span {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				border: 1px solid rgba(43, 96, 114, 0.13);
				border-radius: 19px;
				color: #9eb6be;
				display: inline-flex;
				font-size: 14px;
				font-weight: 700;
				height: 18px;
				letter-spacing: -0.77px;
				margin-left: 5px;
				padding: 0 8px;
				font-family: $font1;
			}
		}
	}

	.stat-box {
		background: #fff;
		border: 1px solid #e6eeee;
		border-radius: 10px;
		box-shadow: 0 10px 20px -15px #dae9ed;
		padding: 25px 30px;
		position: relative;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			height: 50px;
			width: 50px;
			left: -25px;
			top: -25px;
			filter: blur(70px);
			opacity: 0.5;
			background: $green;
		}
		i {
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			flex-shrink: 0;
			font-size: 27px;
			&.green {
				background-image: $green-gradient;
			}
			&.red {
				background-image: linear-gradient(180deg, #dd556c, #ee2f4f);
			}
			&.orange {
				background-image: $orange-gradient;
			}
			&.blue {
				background-image: $telegram-gradient;
			}
		}
		b {
			color: #201e32;
			display: block;
			font-style: normal;
			font-weight: 700;
			letter-spacing: -0.035em;
			margin-bottom: 0px;
			font-size: 25px;
		}
		span {
			color: $text;
			font-family: $font1;
			font-size: 10px;
			font-style: normal;
			line-height: 1;
			font-weight: 400;
			letter-spacing: 2.5px;
		}
	}

	.box-account {
		background: rgba(#fff, 0.95);
		backdrop-filter: blur(13.5px);
		border: 1px solid #e6eeee;
		border-radius: 10px;
		padding: 25px 30px;
		position: relative;
		overflow: hidden;
		box-shadow: 0 10px 20px -15px #dae9ed;
		&.no-padding {
			padding: 0;
		}
		&__reset {
			position: absolute;
			right: 20px;
			top: 20px;
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__icon {
			position: absolute;
			right: -30px;
			top: 50%;

			color: #e6eeee47;
			font-size: 150px;
			margin-top: 15px;
			transform: translate(0, -50%);
			i {
				transform: rotate(-25deg);
			}
		}
		&__balance {
			background: linear-gradient(to bottom left, rgba(221, 233, 235, 0), rgba(221, 233, 235, 0.5));
			border-radius: 10px;
			padding: 10px;
			font-size: 20px;
			font-style: normal;
			color: $dark;
			font-weight: 700;
			letter-spacing: -0.035em;
		}
		&__number {
			-webkit-text-stroke: 1px hsla(187, 6%, 69%, 0.349);
			color: transparent;
			font-family: $font1;
			font-size: 114px;
			font-weight: 700;
			line-height: 0.95;
			margin-top: -21px;
		}
		&__links {
			svg {
				height: 60px;
				width: 60px;
			}
		}
	}

	.security-list {
		&__item {
			display: flex;
		}

		&__icon {
			margin-right: 10px;
			height: 25px;
			width: 25px;
			border: 1px solid #99b6cb;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			margin-top: -2px;
			i.icon-minus {
				font-size: 3px;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;

				i.icon-checkbox {
					font-size: 12px;
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
	}

	.balance {
		background:
			url("../images/bounty-ref-bg.jpg") -150px 0 no-repeat,
			#fff;
		background-size: cover;
		&__right {
			background: #fff;
			/* border: 1px solid #000; */
			margin-left: -15px;
			border-radius: 15px;
		}
		&__item {
		}
		&__dhs {
			display: flex;
			align-items: center;
			abbr {
				margin-right: 15px;
				color: #e1e4eb;
				font-size: 28px;
				display: inline-flex;
				align-items: center;
				margin-top: -6px;
			}
			b {
				-webkit-text-fill-color: transparent;
				background: linear-gradient(269deg, #2d6979, #3c8689);
				background-clip: text;
				-webkit-background-clip: text;
				font-size: 32px;
				font-weight: 900;
				letter-spacing: -0.8px;
				line-height: 90.2%;
				font-family: $font1;
			}
			span {
				align-items: center;
				border: 1px solid rgba(43, 96, 114, 0.13);
				border-radius: 19px;
				color: #9eb6be;
				display: inline-flex;
				font-size: 14px;
				font-weight: 700;
				font-family: $font1;
				text-transform: uppercase;
				height: 18px;
				letter-spacing: -0.77px;
				margin-left: 5px;
				padding: 0 8px;
			}
		}
		&__icon {
			display: flex;
			align-items: center;
			height: 65px;
			justify-content: center;
			position: relative;
			width: 65px;
			border: 1px solid #ebefef;
			margin-right: 20px;
			border-radius: 50%;
			img {
				height: 58px;
			}
		}

		&__value {
			span {
				font-family: $font1;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				color: #929292;
				letter-spacing: 0.35em;
				margin-bottom: 10px;
			}
			b {
				color: #201e32;
				display: block;
				font-size: 34px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: -0.035em;
			}
		}

		&__list {
			border: 1px solid #e6eeee;
			border-radius: 10px;
			padding: 4px;
			i {
				width: 35px;
				flex-shrink: 0;
				display: flex;
				font-size: 23px;
				color: rgba($dark, 0.3);
				align-items: center;
			}
			span {
				color: #929292;
				font-family: $font1;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				letter-spacing: 2.5px;
				line-height: 1;
				display: block;
				margin-bottom: 5px;
			}
			b {
				color: #201e32;
				display: block;
				font-size: 25px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: -0.035em;
			}
		}

		&__info {
			display: flex;
			align-items: center;
			padding: 20px 30px;
			&.dark {
				background: linear-gradient(to bottom left, rgba(221, 233, 235, 0), rgba(221, 233, 235, 0.5));
				border-radius: 10px;
			}
		}

		&__link {
			height: 50px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #fff;
			background: linear-gradient(to left bottom, #fff 30%, #c9d8e7);
			border-radius: 50%;
			font-size: 17px;
			i {
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}

	.deposit-list {
		.progress {
			width: calc(100% - 100px);
			height: 15px;
			margin-left: 100px;
			display: block;
			overflow: hidden;
			background: #eff4f5;
			border-radius: 8px;
			> div {
				height: 100%;
				border-radius: 8px;
				display: flex;
				background-color: $green;
				@include stripes(0.2, #fff);
			}
			&.closed {
				> div {
					width: 100% !important;
					background-color: $red;
				}
			}
		}

		.status {
			display: flex;
			align-items: center;
			flex-shrink: 0;
			white-space: nowrap;
			position: relative;
			color: $dark;
			font-family: $font;
			font-size: 19px;
			font-style: normal;
			font-weight: 700;
			letter-spacing: -1.1px;
			position: absolute;
			background: #fff;
			border-radius: 15px;
			padding: 5px 12px;
			top: 14px;
			left: 30px;
			border: 2px solid $red;
			i {
				background: $youtube-gradient;
				border-radius: 50%;
				flex-shrink: 0;
				height: 20px;
				margin-top: 1px;
				position: relative;
				width: 20px;
				&:before {
					background: #fff;
					border-radius: 50%;
					display: block;
					height: 12px;
					width: 12px;
					left: 50%;
					content: "";
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					z-index: 0;
				}
			}
			&--active {
				border-color: $green;
				i {
					background: $green-gradient;
				}
			}
		}
	}
}

.popover-list {
	padding: 0 10px;
	li {
		display: flex;
		line-height: 1;
		font-family: $font;
		color: $text;
		width: 100%;
		white-space: nowrap;
		padding: 10px 0;
		i {
			margin-right: 10px;
			width: 22px;
			color: #ffb13d;
			margin-top: 1.5px;

			display: inline-flex;
			align-items: center;
			justify-content: center;
			&.icon-signup {
				color: $green;
			}
		}
		b,
		a {
			margin-left: auto;
			padding-left: 10px;
			color: $dark;
			font-weight: 500;
		}
	}
}
