p {
	margin: 0;
	display: block;
	font-weight: normal;
	color: $text;
	font-weight: 400;
	&.s21 {
		font-size: 21px;
		line-height: 130%;
	}
	&.s18 {
		font-size: 18px;
	}
	&.s19 {
		font-size: 19px;
	}
	&.s24 {
		font-size: 24px;
	}
	&.s15 {
		font-size: 15px;
	}
	&.s26 {
		font-size: 26px;
		line-height: 119.2%;
	}
	&.bold {
		font-weight: bold;
	}
	&.medium {
		font-weight: 500;
	}
	b {
		font-weight: 500;
		&.dark {
			color: #424242;
		}
	}
	&.dark {
		color: $black;
	}
	&.white {
		color: #fff;
	}
	&.bordered {
		position: relative;
		padding: 15px 0 15px 40px;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			width: 10px;
			border-radius: 10px 0 0 10px;
			border: 0.5px solid #d4dde2;
			border-right: 0;
		}
		&--right {
			padding-left: 0;
			padding-right: 40px;
			&:before {
				right: 0;
				left: auto;
				border: 0.5px solid #d4dde2;
				border-left: 0;
				border-radius: 0 10px 10px 0;
			}
		}
	}
}

mark {
	background: #dbe3e5;
}

a {
	color: $green1;
	text-decoration: none;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}
	&[data-toggle="modal"] {
		cursor: pointer;
	}
}

.subtitle {
	position: relative;
	color: #7e5d2e;
	font-family: $font1;
	margin-bottom: 24px;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.35em;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	svg {
		margin-right: 15px;
	}
	&--1 {
		color: #929292;
	}
	&--2 {
		color: rgba(#fff, 0.75);
	}
	&--center {
		justify-content: center;
	}
}

.title {
	margin-top: 0;
	font-style: normal;
	font-weight: 700;
	font-family: $font;
	letter-spacing: -3.45px;
	&__bg {
		padding-right: 2px;
		&--yellow {
			background: linear-gradient(93deg, #ffda41 0%, #fdb448 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-family: Aeonik;
			line-height: 1.2;
		}
		&--green {
			background: linear-gradient(270deg, #3d878a 0%, #2e6a7a 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			line-height: 1.2;
		}
		&--dark {
			background: linear-gradient(to right, rgba($black, 0.4), $black 20%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&--white {
			background: linear-gradient(272deg, rgba($white, 0.4) 0%, rgba($white, 1) 20%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&--yellow {
			background: linear-gradient(232deg, #ffda41 0%, #fbae33 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&--facebook {
			background: linear-gradient(232deg, #009fff, #0078e6);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&--youtube {
			background: $youtube-gradient;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&--telegram {
			background: $telegram-gradient;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&--whatsapp {
			background: $telegram-whatsapp;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	&__img {
		display: inline-flex;
		align-items: center;
		margin-right: 15px;
		justify-content: center;
		flex-shrink: 0;
		width: 86px;
		border: 1px dashed #bb8f5a;
		height: 86px;
		border-radius: 50%;
		img {
			margin-top: -5px;
		}
	}
	&--white {
		color: #fff;
	}
}

h1 {
	&.title {
		color: $white;
		font-size: 69px;
		line-height: 0.9;
		.title__bg {
			font-size: 107px;
			margin-top: -5px;
			line-height: 1.25;
			&--small {
				margin-top: 0;
				font-size: 69px;
			}
		}
	}
}

h2 {
	&.title {
		font-size: 69px;
		line-height: 53.5px;
	}
}

h3 {
	&.title {
		margin: 0;
		font-size: 52px;
		line-height: 47px;
		letter-spacing: -2.6px;
	}
}

h4 {
	&.title {
		margin: 0;
		font-size: 36px;
		line-height: 38px;
		letter-spacing: -1.7px;
	}
}
