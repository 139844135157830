.table {
	width: 100%;
	position: relative;
}

.table-confirm {
	> tbody {
		tr {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 4px 0;
			td {
				border: none;
				display: flex;
				> div {
					padding: 7px 0px;
					display: block;
					position: relative;
					width: 100%;
					text-align: left;

					small {
						display: block;
						font-size: 14px;
						font-weight: 500;
						color: $text;
						white-space: nowrap;
						margin-bottom: 3px;
					}
					b {
						font-weight: 700;
						letter-spacing: -0.035em;

						color: $dark;
						font-size: 18px;
						display: block;
						line-height: 1.1;
						white-space: nowrap;
						.percent {
							font-size: 14px;
						}
						> span {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}

.table-overlay {
	border-radius: 15px;
	position: relative;
	z-index: 10;
	backdrop-filter: blur(15px);
	border: 1px solid hsla(0, 0%, 100%, 0.05);
}

.table {
	width: 100%;
	margin: 0;
	&--default {
		//border-radius: 15px;
		border-spacing: 0;
		border-collapse: separate;
		overflow: hidden;
		> thead {
			background: none;

			tr {
				th {
					font-family: $font1;
					font-size: 10px;
					font-style: normal;
					font-weight: 400;
					letter-spacing: 0.35em;
					position: relative;
					text-transform: uppercase;
					background: none;
					padding: 10px 25px;
					color: $text;
					background: #e6eeee61;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
		> tbody {
			tr {
				border-bottom: 1px solid #e6eeee;
				&:last-child {
					td {
						border-bottom: none;
					}
				}
				td {
					&.description {
						color: #a9bdbd;
						font-size: 15px;
						padding: 10px 25px;
						background: #e6eeee61;
					}
					vertical-align: middle;
					font-weight: 400;
					border-right: 1px solid #e6eeee;

					color: $text;
					&:last-child {
						border: none;
					}
					small {
						font-size: 14px;
					}
					.nowrap {
						white-space: nowrap;
					}
					.white {
						color: #fff;
					}
					.dark {
						color: $dark;
					}
					.green {
						color: $green;
					}
					.oper {
						font-weight: 500;
					}
					.date {
						display: flex;
						i {
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							background-image: $orange-gradient;
						}
					}
					.profit {
						background: $green-gradient;
						display: flex;
						align-items: center;
						padding: 5px 15px;
						color: #fff;
						border-radius: 10px;
						font-size: 25px;
						font-style: normal;
						font-weight: 500;
						letter-spacing: -0.035em;
						i {
							opacity: 0.5;
							font-size: 21px;
						}
					}
					.amount {
						display: block;
						font-size: 25px;
						font-style: normal;
						font-weight: 700;
						letter-spacing: -0.035em;
					}
					.payment {
						color: #929292;
						font-family: $font1;
						font-size: 10px;
						font-style: normal;
						font-weight: 400;
						letter-spacing: 0.35em;
					}

					.status {
						display: flex;
						align-items: center;
						flex-shrink: 0;
						white-space: nowrap;
						position: relative;
						color: $dark;
						font-family: $font;
						font-size: 19px;
						font-style: normal;
						font-weight: 700;
						letter-spacing: -1.1px;
						background: #fff;
						border-radius: 15px;
						padding: 5px 12px;
						border: 2px solid $red;
						i {
							background: $youtube-gradient;
							border-radius: 50%;
							flex-shrink: 0;
							height: 20px;
							margin-top: 1px;
							position: relative;
							width: 20px;
							&:before {
								background: #fff;
								border-radius: 50%;
								display: block;
								height: 12px;
								width: 12px;
								left: 50%;
								content: "";
								position: absolute;
								top: 50%;
								transform: translate(-50%, -50%);
								z-index: 0;
							}
						}
						&--active {
							border-color: $green;
							i {
								background: $green-gradient;
							}
						}
					}

					padding: 25px;
				}
				&.active {
					background: rgba($green, 0.05);
				}
			}
		}
	}
}
