@keyframes autofill {
	to {
		background-color: transparent;
	}
}

@keyframes arrow-right {
	0%,
	100% {
		opacity: 1;
		transform: translate(0px, 0);
	}

	50% {
		transform: translate(10px, 0px);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotate-back {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(-360deg);
	}
}

@keyframes animate-stripes {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 20px 0;
	}
}

@keyframes svg-line {
	0% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: -15;
	}
}

@keyframes moveLeft {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-2745px, 0px, 0);
	}
}

@keyframes move-text-top {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(0, -3302.81px, 0);
	}
}

@keyframes myAnimation {
	0% {
		opacity: 0;
		stroke-dasharray: 0px 1px;
	}
	50% {
		opacity: 0.7;
		stroke-dasharray: 1px 1px;
	}
	55% {
		opacity: 0;
		stroke-dasharray: 1px 1px;
	}
	100% {
		opacity: 0;
		stroke-dasharray: 0px 1px;
	}
}

@keyframes animLine {
	0% {
		offset-distance: 70%;
		background-color: $green;
	}
	44.995% {
		background-color: $green;
	}
	45% {
		background-color: $red;
		offset-distance: 25%;
	}

	70% {
		offset-distance: 0%;
	}
	70.000001% {
		background-color: $red;
		offset-distance: 100%;
	}

	90% {
		background-color: $red;
	}
	90.001% {
		background-color: $yellow;
	}
	99.999% {
		background-color: $yellow;
	}
	100% {
		background-color: $green;
		offset-distance: 70%;
	}
}
