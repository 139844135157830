.social {
	position: relative;

	&__label {
		color: #624f31;
		font-family: RoadRadio;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 102.2%;
		letter-spacing: 0.35em;
		text-transform: uppercase;
	}
	&__links {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	&__item {
		position: relative;
		z-index: 10;
		height: 30px;
		width: 30px;
		font-size: 20px;
		color: rgba(#352b19, 0.31);
		display: inline-flex;
		align-items: center;
		transition: all 0.3s ease-in-out;
		justify-content: center;
		cursor: pointer;
		i {
			&.icon-vk {
				font-size: 17px;
			}
		}
		&:hover {
			color: rgba(#352b19, 0.5);
		}
	}
	&--footer {
		.social__item {
			color: rgba(#d2d4e4, 0.37);
			&:hover {
				color: rgba(#d2d4e4, 0.6);
			}
		}
	}
	&--form {
		.social__item {
			color: rgba(#c2cbcd, 0.75);
			&:hover {
				color: rgba(#c2cbcd, 1);
			}
		}
	}
}
