.offcanvas {
	.offcanvas-header {
		padding: 0;
		display: flex;
		justify-content: start;
		padding: 35px 0;
		@include media-breakpoint-down(md) {
			padding: 27px 0;
		}
		.btn-close {
			cursor: pointer;
			z-index: 20;
			margin: 0;
			outline: none;
			position: absolute;
			right: 35px;
			top: 35px;
			outline: none;
			height: 40px;
			width: 40px;
			border: 1px solid #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 23px;
			box-shadow: none;
			border: none;
			border-radius: 0;
			@include media-breakpoint-down(md) {
				right: 23px;
				top: 26px;
			}
		}
	}
	.offcanvas-body {
		padding: 30px 0;
	}
	.offcanvas-footer {
		padding: 30px;
	}
	&.offcanvas-dashboard {
		.offcanvas-header .btn-close {
		}
	}
	&__background {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: 0;
	}
	&__round {
		position: absolute;
		height: 900px;
		width: 900px;
		left: 50%;
		top: 110px;
		transform: translate(-50%, 0%);

		> div {
			height: 100%;
			width: 100%;
			border: 2px solid hsla(0, 0%, 100%, 0.1);
			border-radius: 50%;
		}
	}
	background: $black;
	&.offcanvas-bottom {
		border: none;
		width: 100%;
		@extend %animated;
		height: 100%;
	}
	overflow: hidden;
}

.offcanvas-backdrop {
}
