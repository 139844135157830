.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0px 33px;
	font-size: 15px;
	transition: all 0.4s;
	font-weight: 700;
	min-width: 55px;
	border-radius: 13px;
	white-space: nowrap;
	border: none;
	text-decoration: none;
	letter-spacing: 0.375px;
	height: 50px;
	line-height: 1;
	&--yellow {
		color: #634a0b;
		border: 1px solid #fff;
		background: linear-gradient(180deg, #ffda41 0%, #ff993a 50%, #ffda41 100%);
		background-size: auto 200%;
		background-position: center top;
		box-shadow:
			0px -4px 9px 0px #ffd33f inset,
			0px 10px 15px -5px rgba(#9d7819, 0.25);
		&:hover,
		&:focus {
			color: #634a0b;
			background-position: center bottom;
		}
	}
	&--yellow1 {
		border: 1px solid rgba(255, 255, 255, 0.45);
	}

	&--green {
		color: #fff;
		border: 1px solid #5fb5ad;
		background: linear-gradient(#4fa797, #285c6e 50%, #4fa797 100%);
		background-size: auto 200%;
		background-position: center top;
		box-shadow:
			0px -4px 9px 0px #499b91 inset,
			0px 10px 15px -8px rgba(#1e4a47, 0.2);
		&:hover,
		&:focus {
			color: #fff;
			background-position: center bottom;
		}
	}

	&--outline {
		background: transparent;
		color: $orange;
		position: relative;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			@include border-gradient(#ffda41, #ff993a, 45deg, 2px);
			border-radius: 13px;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}
		&:hover,
		&:focus {
			background: rgba(#ff993a, 0.1);
			color: $orange;
		}
	}

	&--outline1 {
		background: transparent;
		color: $green;
		position: relative;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			@include border-gradient(#285c6e, #4fa797, 45deg, 2px);
			border-radius: 13px;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}
		&:hover,
		&:focus {
			background: rgba(#285c6e, 0.1);
			color: $green;
		}
	}

	&--empty {
		color: #d4e7e2;
		background: none;
		.button__icon {
			opacity: 0.8;
			font-size: 120%;
		}
		&:hover,
		&:focus {
			color: #fff;
		}
	}

	&--large {
		padding: 0 44px;
		height: 55px;
		font-size: 17px;
	}

	&--submit {
		input {
			height: 100%;
			width: 100%;
			background: none;
			box-shadow: none;
			border: none;
		}
	}
	&:hover,
	&:focus {
		span {
			&:before {
			}
		}
	}
}
