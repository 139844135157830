$icon_font_name:"iconfont";
@font-face {
	font-family:$icon_font_name;
	src: url('../fonts/icons/iconfont.eot');
	src: url('../fonts/icons/iconfont.eot?#iefix') format('eot'),
	url('../fonts/icons/iconfont.woff') format('woff'),
	url('../fonts/icons/iconfont.woff2') format('woff'),
	url('../fonts/icons/iconfont.ttf') format('truetype'),
	url('../fonts/icons/iconfont.svg#iconfont') format('svg');
	font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
	display: inline-flex;
	align-items: center;
	line-height: 1;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family:$icon_font_name;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	speak: none; 

	text-decoration: none;
	text-transform: none;
}

.icon-account:before { content:'\E001'; }
.icon-add:before { content:'\E002'; }
.icon-arrow-bottom:before { content:'\E003'; }
.icon-arrow-left-wide:before { content:'\E004'; }
.icon-arrow-right-wide:before { content:'\E005'; }
.icon-arrow-right:before { content:'\E006'; }
.icon-bonus:before { content:'\E007'; }
.icon-browser:before { content:'\E008'; }
.icon-calculator:before { content:'\E009'; }
.icon-calendar:before { content:'\E00A'; }
.icon-cancel:before { content:'\E00B'; }
.icon-close:before { content:'\E00C'; }
.icon-copy:before { content:'\E00D'; }
.icon-date:before { content:'\E00E'; }
.icon-deposit:before { content:'\E00F'; }
.icon-deposits:before { content:'\E010'; }
.icon-edit:before { content:'\E011'; }
.icon-email:before { content:'\E012'; }
.icon-error:before { content:'\E013'; }
.icon-facebook:before { content:'\E014'; }
.icon-history:before { content:'\E015'; }
.icon-home:before { content:'\E016'; }
.icon-ico-view-hide:before { content:'\E017'; }
.icon-ico-view:before { content:'\E018'; }
.icon-info:before { content:'\E019'; }
.icon-instagram:before { content:'\E01A'; }
.icon-ip:before { content:'\E01B'; }
.icon-login:before { content:'\E01C'; }
.icon-logout:before { content:'\E01D'; }
.icon-money:before { content:'\E01E'; }
.icon-more:before { content:'\E01F'; }
.icon-opers:before { content:'\E020'; }
.icon-partners:before { content:'\E021'; }
.icon-password:before { content:'\E022'; }
.icon-percent:before { content:'\E023'; }
.icon-plus:before { content:'\E024'; }
.icon-profile:before { content:'\E025'; }
.icon-profit:before { content:'\E026'; }
.icon-promo:before { content:'\E027'; }
.icon-question:before { content:'\E028'; }
.icon-recovery:before { content:'\E029'; }
.icon-reset:before { content:'\E02A'; }
.icon-save:before { content:'\E02B'; }
.icon-security:before { content:'\E02C'; }
.icon-settings:before { content:'\E02D'; }
.icon-signup:before { content:'\E02E'; }
.icon-star:before { content:'\E02F'; }
.icon-stat:before { content:'\E030'; }
.icon-stat1:before { content:'\E031'; }
.icon-success:before { content:'\E032'; }
.icon-telegram:before { content:'\E033'; }
.icon-tfa:before { content:'\E034'; }
.icon-tiktok:before { content:'\E035'; }
.icon-timer-pause:before { content:'\E036'; }
.icon-timer:before { content:'\E037'; }
.icon-trand:before { content:'\E038'; }
.icon-twitter:before { content:'\E039'; }
.icon-vk:before { content:'\E03A'; }
.icon-wallet:before { content:'\E03B'; }
.icon-withraw:before { content:'\E03C'; }
.icon-youtube:before { content:'\E03D'; }