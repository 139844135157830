.ui-datepicker-prev,
.ui-datepicker-next {
	transition: all 0.4s ease-in-out;
	display: inline-block;
	width: 20px;
	height: 20px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	font-family: "iconfont";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 20px;
	color: rgba($dark, 0.4);
	position: relative;
	z-index: 100;
	margin-left: 5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	float: left;
	&:hover {
		color: $dark;
	}
	&:before {
		content: "\e005";
	}
	> span {
		display: none;
	}
}
.ui-datepicker-next {
	margin-right: 5px;
	margin-left: 0;
	float: right;
	&:before {
		content: "\e004";
	}
}

.ui-datepicker {
	font-family: $font1;
	td {
		span {
			transition: all 0.4s ease-in-out;
			display: inline-block;
			text-align: center;
			width: 36px;
			height: 36px;
			font-size: 15px;
			line-height: 36px;
			border-radius: 2px;
			&:hover {
				color: #050505;
			}
		}
		a {
			transition: all 0.4s ease-in-out;
			display: inline-block;
			text-align: center;
			width: 36px;
			height: 36px;
			font-size: 15px;
			line-height: 36px;
			border-radius: 2px;
			&:hover {
				color: #050505;
			}
		}
	}
	width: 350px;
	display: none;
	height: auto;
	margin: 2px auto 0;
	z-index: 10000 !important;
	border-radius: 4px;
	padding: 10px;
	border: none;
	background: rgba(#fff, 0.92);
	backdrop-filter: blur(12.5px);

	border: none;
	border-radius: 10px;
	border: 1px solid #d4dde5;
	position: relative;

	a {
		text-decoration: none;
		margin-top: 3px;
	}
	table {
		width: 100%;
	}
	thead {
	}
	th {
		font-size: 10px;
		font-weight: 600;
		opacity: 0.5;
		padding: 5px 0 15px 0;
		color: #525860;
		text-transform: uppercase;
		text-align: center;
	}
	tbody {
		td {
			border: none;
			text-align: center;
			padding: 2px 0px;
			&:last-child {
				border-right: 0px;
			}
		}
		tr {
			border: none;
			&:last-child {
				border-bottom: 0px;
			}
		}
	}
}
.ui-datepicker-buttonpane {
	display: none;
	button[type="button"] {
		transition: all 0.4s ease-in-out;
		display: inline-block;
		*display: inline;
		*zoom: 1;
		vertical-align: top;
		padding: 10px 15px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-size: 12px;
		border: none;
		color: #fff;
		outline: none;
		border-radius: 4px;

		font-weight: bold;
		text-transform: uppercase;
		&:hover {
		}
	}
	padding-top: 10px;
	text-align: center;
	button[type="button"].ui-datepicker-current {
		display: none;
	}
}
.ui-datepicker-header {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	line-height: 30px;
}
.ui-datepicker-title {
	text-align: center;
	position: relative;
	color: $dark;
}
.ui-datepicker-calendar {
	.ui-state-default {
		margin-top: 0px;
		background: #fff;
		color: rgba($dark, 0.8);
		font-weight: 500;
	}
	.ui-state-hover {
		background: #f1f5f8;
	}
	.ui-state-active {
		background: $green-gradient;
		border: none;
		color: #fff;
		text-shadow: none;
		position: relative;
		margin: 0px;
		border-radius: 4px;
		&:hover {
			color: #fff;
		}
	}
	td {
		&:first-child {
			.ui-state-active {
				margin-left: 0;
			}
		}
		&:last-child {
			.ui-state-active {
				margin-right: 0;
			}
		}
	}
	tr {
		&:last-child {
			.ui-state-active {
				margin-bottom: 0;
			}
		}
	}
}
.ui-datepicker-unselectable {
	.ui-state-default {
		background: #dfebee;
		color: $text;
		opacity: 0.5;
	}
}
select.ui-datepicker-month {
	box-shadow: inset 0 -3px rgba(213, 213, 213, 0.12);
	border: 1px solid #bbb;
	padding: 8px 20px;
	font-size: 14px;
	font-weight: 600;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	border-radius: 3px;
	margin: 0 5px;
	width: 116px;
}
select.ui-datepicker-year {
	box-shadow: inset 0 -3px rgba(213, 213, 213, 0.12);
	border: 1px solid #bbb;
	padding: 8px 20px;
	font-size: 14px;
	font-weight: 600;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	border-radius: 3px;
	margin: 0 5px;
	width: 116px;
}
