.breadcrumb {
	display: flex;
	align-items: center;
	i {
		font-size: 13px;
	}
	.breadcrumb-item {
		height: 37px;
		padding: 10px 23px;
		font-family: $font1;
		font-size: 11px;
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0.35em;
		border: 1px solid rgba(#ad7e4d, 0.5);
		border-radius: 15px;
		a {
			display: flex;
			align-items: center;
			color: #6c5033;
			i {
				opacity: 0.7;
				margin-right: 8px;
			}
		}
		&.active {
			margin-left: -7px;
			border: none;
			color: #6c5033;
			background: linear-gradient(91deg, #e3bf99, transparent);
		}
	}
}
