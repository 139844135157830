.container {
	max-width: 1200px;
	width: 1200px !important;
}

@for $i from 0 through 11 {
	.col-#{$i + 1} {
		width: (($i + 1) * 100%/ (12)) !important;
		flex: 0 0 (($i + 1) * 100%/ (12));
	}
}
