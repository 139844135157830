.tooltip {
	max-width: 300px;
	.tooltip-inner {
		border: none;
		border-radius: 8px;
		box-shadow: none;
		color: #fff;

		background: $dark;
		font-family: $font;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.1;
		letter-spacing: 0;

		padding: 15px 20px;
		z-index: 1000;
	}

	&.show {
		opacity: 1;
	}
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
	border-bottom-color: $dark;
}
