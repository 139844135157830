.dropdown {
	&--langs {
		button.dropdown-toggle {
			border-radius: 88px;
			background: linear-gradient(228deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%);
			box-shadow: 1px -1px 4px 0px rgba(255, 255, 255, 0.25) inset;
			backdrop-filter: blur(9px);
			align-items: center;
			color: $white;
			margin: 0;
			outline: none;
			border: none;
			font-weight: 500;
			padding: 7px 13px 7px 10px;
			display: flex;
			span {
				display: block;
				margin-right: 8px;
				@include media-breakpoint-only(lg) {
					display: none;
				}
			}
			i.icon-arrow-dropdown {
				font-size: 7px;

				display: flex;
				line-height: 1;
				color: #fff;
			}
			&:after {
				display: none;
			}
			&:focus,
			&:active {
				outline: none;
				//box-shadow: none;
			}
			&.show {
			}
		}
		.dropdown-menu {
			min-width: 78px;
			top: -2px;
			&.show {
				display: flex;
			}
			flex-wrap: wrap;
			.dropdown-item {
				&.disabled {
					opacity: 0.6;
				}
			}
			a {
				padding: 5px 13px;
				border-radius: 0px;
				background: none;
				transition: all 0.2s ease-in-out;
				position: relative;
				display: flex;
				i {
					display: block;
					border-radius: 4px;
					width: 18px;
					height: 15px;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
				}
				img {
					height: 15px;
				}
				color: #637f95;
				font-size: 14px;
				align-items: center;
				font-weight: 500;
				letter-spacing: 0.1em;

				&:hover,
				&.active {
				}
			}
			border-radius: 10px;
			border: none;
			margin-top: -4px;
		}
	}
}
