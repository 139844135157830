%flex-top {
	align-items: flex-start;
}

%flex-vertical {
	align-items: center;
}

%flex-bottom {
	align-items: flex-end;
}

%flex-full {
	align-items: stretch;
}

%flex-row {
	flex-direction: row;
}

%flex-col {
	flex-direction: column;
}

%flex-center {
	justify-content: center;
}

%flex-justify {
	justify-content: space-between;
}

%flex-left {
	justify-content: flex-start;
}
%flex-start {
	justify-content: flex-start;
}

%flex-end {
	justify-content: flex-end;
}

%flex-right {
	justify-content: flex-end;
}

%box-sizing {
	box-sizing: border-box;
}

%animated {
	transition: all 0.4s ease-in-out;
}

%inline-block {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	vertical-align: top;
}

%before {
	content: "";
	display: block;
	z-index: 0;
	position: absolute;
}

%noselect {
	user-select: none;
}

%text-gradient {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

%text-background {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin text-outline($color: black, $stroke: 1px) {
	color: transparent;
	-webkit-text-stroke-width: $stroke;
	-webkit-text-stroke-color: $color;
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-moz-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-moz-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-moz-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $value;
		-moz-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	justify-content: $value;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
		-moz-box-direction: reverse;
		-moz-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-moz-box-direction: normal;
		-moz-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
		-moz-box-direction: reverse;
		-moz-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
		-moz-box-direction: normal;
		-moz-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
	-webkit-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $value;
	}
	flex-wrap: $value;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: ($size / 16px) * 1rem;
}

@mixin svg($color, $width) {
	svg {
		path,
		line,
		polyline {
			stroke: $color;
			stroke-width: $width;
		}
		path {
			fill: transparent;
		}
	}
}

@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;

		transform: translate(-50%, 0%);
	} @else if ($vertical) {
		top: 50%;

		transform: translate(0%, -50%);
	} @else {
		transform: translate(0%, 0%);
	}
}

@mixin transform($transforms) {
	transform: $transforms;
}

@mixin skew($x, $y) {
	transform: skew($x, $y);
}

@mixin scale($x) {
	transform: scale($x);
}

@mixin translate($x, $y) {
	transform: translate($x, $y);
}

@mixin scale($x) {
	transform: scale($x);
}

@mixin scale3d($x, $y, $z) {
	transform: scale3d($x, $y, $z);
}

@mixin rotate($degrees) {
	transform: rotate($degrees);
}

@mixin filter($filter-type, $filter-amount) {
	filter: $filter-type + unquote("(#{$filter-amount})");
}

@mixin animation($dutation) {
	transition: all $dutation ease-in-out;
}

@mixin background-image($img, $type: png, $x: 0, $y: 0, $repeat: no-repeat) {
	background-image: url("../images/#{$img}.#{$type}");
	background-image: -webkit-image-set(
		url("../images/#{$img}.#{$type}") 1x,
		url("../images/#{$img}@2x.#{$type}") 2x,
		url("../images/#{$img}@3x.#{$type}") 3x
	);
	background-image: image-set(
		url("../images/#{$img}.#{$type}") 1x,
		url("../images/#{$img}@2x.#{$type}") 2x,
		url("../images/#{$img}@3x.#{$type}") 3x
	);
	background-repeat: #{$repeat};
	background-position: #{$x}px #{$y}px;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin anim($animate...) {
	$max: length($animate);
	$animations: "";

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "};
		}
	}
	animation: $animations;
}

@mixin stripes($opacity, $color, $size: 25px, $deg: -135deg) {
	background-size: $size $size;
	background-image: linear-gradient(
		$deg,
		rgba($color, $opacity) 25%,
		transparent 25%,
		transparent 50%,
		rgba($color, $opacity) 50%,
		rgba($color, $opacity) 75%,
		transparent 75%,
		transparent
	);
	&.animated {
		animation: animate-stripes 1s linear infinite;
	}
	&.reverse {
		animation-direction: reverse;
	}
	&:hover {
		//animation: animate-stripes 1s linear infinite;
	}
	&.static {
		animation: animate-stripes 100000s linear infinite;
	}
}

//@include scrollbars(10px, pink, red);

@mixin border-gradient($color1: #fff, $color2: #fff, $deg: 45deg, $border: 1px) {
	background: linear-gradient($deg, $color1, $color2) border-box;
	-webkit-mask:
		linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	border: $border solid transparent;
}

@mixin border-radial-gradient($color1: #fff, $color2: #fff, $percent: 25%) {
	background:
		radial-gradient(circle at 0% 0%, $color1 0%, $color2 $percent) border-box,
		radial-gradient(circle at 100% 100%, $color1 0%, $color2 $percent) border-box;

	-webkit-mask:
		linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	border: 1px solid transparent;
}

@mixin border-radial-gradient-x2($color1: #fff, $color2: #fff, $percent: 25%) {
	background:
		radial-gradient(circle at 0% 0%, $color1 0%, $color2 $percent) border-box,
		radial-gradient(circle at 100% 0%, $color1 0%, $color2 $percent) border-box,
		radial-gradient(circle at 0% 100%, $color1 0%, $color2 $percent) border-box,
		radial-gradient(circle at 100% 100%, $color1 0%, $color2 $percent) border-box;

	-webkit-mask:
		linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	border: 1px solid transparent;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
	&::-webkit-scrollbar {
		width: $size;
		height: $size;
	}
	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}
	&::-webkit-scrollbar-track {
		background: $background-color;
	}
	& {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}

@function em($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $pixels / $context * 1em;
}

@mixin gradient-striped($color: rgba(255, 255, 255, 0.15), $angle: 45deg) {
	background-image: -webkit-linear-gradient(
		$angle,
		$color 25%,
		transparent 25%,
		transparent 50%,
		$color 50%,
		$color 75%,
		transparent 75%,
		transparent
	);
	background-image: -o-linear-gradient(
		$angle,
		$color 25%,
		transparent 25%,
		transparent 50%,
		$color 50%,
		$color 75%,
		transparent 75%,
		transparent
	);
	background-image: linear-gradient(
		$angle,
		$color 25%,
		transparent 25%,
		transparent 50%,
		$color 50%,
		$color 75%,
		transparent 75%,
		transparent
	);
}

@mixin font($fname, $fstyle, $fweight, $furl, $fstrech: normal) {
	@font-face {
		font-family: $fname;
		font-style: $fstyle;
		font-weight: $fweight;
		font-stretch: $fstrech;
		src: url($furl) format("woff2");
		//local($fname),
		font-display: swap;
	}
}

@mixin counter($var, $sep) {
	counter-reset: list + $var;
	> li {
		&:before {
			content: counter(list + $var) $sep;
			counter-increment: list + $var;
		}
	}
}

@function css-function($function, $values...) {
	@return $function + unquote("(") + $values + unquote(")");
}

@function css-min($values...) {
	@return css-function(min, $values);
}

@function css-max($values...) {
	@return css-function(max, $values);
}

@function css-clamp($values...) {
	@return css-function(clamp, $values);
}

$LIGHTING_FLOOR: 1;
$LIGHTING_CEIL: 2;
$LIGHTING_FLAT: 3;

@mixin text3d(
	$primary,
	$depth: 5,
	$shadowsteps: 5,
	$shadowincrementer: 3px,
	$shadowopacity: 0.4,
	$primaryshadowcolour: #000,
	$lighting: $LIGHTING_CEIL
) {
	$predefinedShadows: (
		0 0 5px rgba($primaryshadowcolour, 0.05),
		0 -1px 3px rgba($primaryshadowcolour, 0.2),
		0 3px 5px rgba($primaryshadowcolour, 0.2)
	);
	$value: ();
	@for $i from 1 through $depth {
		$num: $i + px;
		$num1: $i * 1.3 + px;
		$hueadjust: $i * 0.2;
		@if ($lighting == $LIGHTING_FLOOR) {
			$hueadjust: ($i * 2 - $depth - 5) * 1%;
		} @else if ($lighting == $LIGHTING_CEIL) {
			$hueadjust: -($i * 2 + $depth - 10) * 1%;
		} @else if ($lighting == $LIGHTING_FLAT) {
			$hueadjust: -$depth * 1%;
		}
		$colour: adjust-color($primary, $lightness: $hueadjust);
		$theShadow: $num1 $num 1px $colour;
		$value: append($value, $theShadow, comma);
	}

	@for $i from 1 through $shadowsteps {
		@if ($i >= length($predefinedShadows)) {
			$dist: $i * $shadowincrementer;
			$value: append($value, 0 $dist $dist rgba($primaryshadowcolour, $shadowopacity));
		} @else {
			$value: append($value, nth($predefinedShadows, $i));
		}
	}

	text-shadow: $value;
}

@mixin box3d(
	$primary,
	$depth: 5,
	$shadowsteps: 5,
	$shadowincrementer: 3px,
	$shadowopacity: 0.4,
	$primaryshadowcolour: #000,
	$lighting: $LIGHTING_CEIL
) {
	$predefinedShadows: (
		0 0 5px rgba($primaryshadowcolour, 0.05),
		0 -1px 3px rgba($primaryshadowcolour, 0.2),
		0 3px 5px rgba($primaryshadowcolour, 0.2)
	);
	$value: ();
	@for $i from 1 through $depth {
		$num: $i + px;
		$num1: $i * 1.5 + px;
		$hueadjust: $i * 0.2;
		@if ($lighting == $LIGHTING_FLOOR) {
			$hueadjust: ($i * 2 - $depth - 5) * 1%;
		} @else if ($lighting == $LIGHTING_CEIL) {
			$hueadjust: -($i * 2 + $depth - 10) * 1%;
		} @else if ($lighting == $LIGHTING_FLAT) {
			$hueadjust: -$depth * 1%;
		}
		$colour: adjust-color($primary, $lightness: $hueadjust);
		$theShadow: $num1 $num 1px $colour;
		$value: append($value, $theShadow, comma);
	}

	@for $i from 1 through $shadowsteps {
		@if ($i >= length($predefinedShadows)) {
			$dist: $i * $shadowincrementer;
			$value: append($value, 0 $dist $dist rgba($primaryshadowcolour, $shadowopacity));
		} @else {
			$value: append($value, nth($predefinedShadows, $i));
		}
	}

	box-shadow: $value;
}
