@keyframes "fadeOut" {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	z-index: 1;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden;
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	.owl-wrapper {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
	}
	.owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		img {
			display: block;
		}
		.owl-lazy {
			opacity: 0;
			transition: opacity 400ms ease;
			&:not([src]) {
				max-height: 0;
			}
		}
		.owl-lazy[src^=""] {
			max-height: 0;
		}
		img.owl-lazy {
			transform-style: preserve-3d;
		}
	}
	.owl-nav.disabled {
		display: none;
	}
	.owl-dots.disabled {
		display: none;
	}
	.owl-nav {
		.owl-prev {
			cursor: pointer;

			user-select: none;
		}
		.owl-next {
			cursor: pointer;

			user-select: none;
		}
		button.owl-prev {
			background: none;
			color: inherit;
			border: none;
			padding: 0 !important;
			font: inherit;
		}
		button.owl-next {
			background: none;
			color: inherit;
			border: none;
			padding: 0 !important;
			font: inherit;
		}
	}
	.owl-dot {
		cursor: pointer;

		user-select: none;
	}
	button.owl-dot {
		background: none;
		color: inherit;
		border: none;
		padding: 0 !important;
		font: inherit;
	}
	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		animation-name: fadeOut;
	}
	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #000;
	}
	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		background: url("owl.video.play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		transition: transform 100ms ease;
		&:hover {
			-ms-transform: scale(1.3, 1.3);
			transform: scale(1.3, 1.3);
		}
	}
	.owl-video-playing {
		.owl-video-tn {
			display: none;
		}
		.owl-video-play-icon {
			display: none;
		}
	}
	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: opacity 400ms ease;
	}
	.owl-video-frame {
		position: relative;
		z-index: 1;
		height: 100%;
		width: 100%;
	}
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel.owl-refresh {
	.owl-item {
		visibility: hidden;
	}
}
.owl-carousel.owl-drag {
	.owl-item {
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}
.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
	.owl-item {
		float: right;
	}
}
.no-js {
	.owl-carousel {
		display: block;
	}
}
.owl-height {
	transition: height 500ms ease-in-out;
}

.owl-carousel {
	.owl-nav {
		display: flex;
		position: absolute;
		right: 0;
		top: -150px;
		background: transparent;
		@include media-breakpoint-down(xl) {
			top: -120px;
		}
		@include media-breakpoint-down(md) {
			top: 100%;
			margin-top: 30px;
			left: 0;
			justify-content: center;
		}
		button.owl-next,
		button.owl-prev {
			z-index: 100;
			outline: none;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			line-height: 1;
			opacity: 1;
			border-radius: 50%;
			border: 1px solid #3e3e3e;
			width: 120px;
			height: 120px;
			margin-left: 40px;
			transition: all 0.2s ease-in-out;
			font-size: 16px;
			i {
				line-height: 1;
				display: flex;
				color: $white;
			}
			&.disabled {
				opacity: 0.3;
				//-webkit-text-fill-color: transparent;
				//-webkit-text-stroke: 1px #b1beba;
			}
			&:hover {
				//color: #fff;
				border-color: rgba(#fff, 0.5);
				i {
					@include anim("arrow-right 1s linear 0s infinite normal");
				}
			}
			@include media-breakpoint-down(xl) {
				height: 80px;
				width: 80px;
				margin-left: 20px;
				font-size: 13px;
			}
		}
		button.owl-prev {
		}
		button.owl-next {
		}
	}
	.owl-dots {
		position: absolute;
		width: 100%;
		bottom: -70px;
		display: flex;
		justify-content: center;
		@include media-breakpoint-down(sm) {
			bottom: -50px;
		}
		.owl-dot {
			margin: 0 15px;

			transition: all 0.4s ease-in-out;
			width: 50px;
			height: 20px;
			opacity: 1;
			border: none;
			background: none;
			position: relative;
			&:before {
				content: "";
				display: block;
				z-index: 0;
				position: absolute;
				height: 4px;
				width: 100%;
				border-radius: 3px;
				top: 50%;
				transform: translate(0, -50%);
				background: #373737;
			}
			&.active {
				width: 100px;
				&:before {
					transition: all 0.4s ease-in-out;
					background: $blue;
				}
			}
		}
	}
}

.news-carousel,
.features-carousel {
	&.owl-carousel {
		.owl-stage-outer {
			overflow: visible;
		}
		.owl-item {
			.item {
				@include media-breakpoint-down(md) {
					max-width: 350px;
				}
				@include media-breakpoint-down(sm) {
					max-width: 240px;
				}
			}
			&.clone {
			}
			&.active {
			}
			&.hidden:not(.active) {
			}
		}
	}
}

/*
.withdrawals-carousel {
	&.owl-carousel .owl-nav {
		background: rgba($color-yellow, 0.06);
		button.owl-next,
		button.owl-prev {
			i {
				color: $color-yellow;
			}
		}
	}
}

.why-carousel,
.plans-carousel {
	&.owl-carousel .owl-nav {
		width: 100%;
		background: none;
		border-radius: 0;
		display: flex;
		justify-content: center;
		button.owl-next,
		button.owl-prev {
			width: 50px;
			border-radius: 25px 0 0 25px;
			background: #d4f3ff17;
			i {
				color: #c7f5ff;
			}
			&.disabled {
				-webkit-text-fill-color: #0000;
				-webkit-text-stroke: 1px #c7f5ffb8;
			}
		}
		button.owl-next {
			border-radius: 0 25px 25px 0;
		}
		&.disabled {
			display: none;
		}
	}
}

.psys-carousel1,
.why-carousel {
	&.owl-carousel .owl-nav {
		button.owl-next,
		button.owl-prev {
			width: 50px;
			border-radius: 25px 0 0 25px;
			background: #c3d5db54;
			i {
				color: #80999d;
			}
			&.disabled {
				-webkit-text-stroke: 1px #80999d;
			}
		}
		button.owl-next {
			border-radius: 0 25px 25px 0;
		}
	}
}

.balance-carousel {
	&.owl-carousel .owl-nav {
		background: none;
		button.owl-next,
		button.owl-prev {
			width: 50px;
			border-radius: 25px 0 0 25px;
			background: #b9b9b929;
			i {
				color: #b5b5b5;
			}
			&.disabled {
				-webkit-text-stroke: 1px #b5b5b5;
			}
		}
		button.owl-next {
			border-radius: 0 25px 25px 0;
		}
	}
}

.plans-carousel,
.opers-carousel,
.why-carousel {
	&.owl-carousel {
		.owl-stage-outer {
			overflow: visible;
		}
		.owl-item {
			opacity: 0;
			@include animation(0.2s);
			&.clone {
				opacity: 0;
			}
			&.active {
				opacity: 1;
			}
			&.hidden:not(.active) {
				opacity: 0;
			}
		}
	}
}

*/
