ul.pagination {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 4px;
	border-radius: 10px;
	border: 1px solid #e6eeee;
	width: auto;
	> li.page-item {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		margin: 0 0px;
		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			height: 55px;
			transition: all 0.3s ease-in-out;
			font-size: 18px;
			color: $text;
			width: auto;
			min-width: 55px;
			font-family: $font;
			font-weight: bold;
			cursor: pointer;
			border-radius: 10px;
			box-shadow: none;
			&.first,
			&.last {
				width: auto;
			}
			&.prev,
			&.next {
			}
		}
		&.active,
		&:hover {
			> a {
				color: $dark;
				background: linear-gradient(to bottom left, rgba(221, 233, 235, 0), rgba(221, 233, 235, 0.5));
			}
		}
	}
	margin: 0;
}
