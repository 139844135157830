@use "sass:math";
@use "sass:color";

@import "utils/mixins";
@import "utils/variables";

// Vendors
@import "vendors/bootstrap";

// Base
@import "base/reset";
@import "base/typography";

// Layouts
@import "layouts/main";

// Components

@import "components/iconfont";
@import "components/navbar";
@import "components/dropdown";
@import "components/buttons";
@import "components/offcanvas";

@import "lib/aos";
@import "components/animation";

@import "lib/owl.carousel";

@import "components/social";
@import "components/breadcrumb";

@import "components/form";
@import "components/range";
@import "components/massage";
@import "components/tooltip";

@import "components/tabs";
@import "components/pagination";

//

//@import "components/modal";

//@import "components/bs-select";
@import "lib/datapicker";
/*
@import "components/popover";
@import "components/calc";



@import "lib/animate";
@import "lib/hightchart";

*/

.header {
	background: url("../images/header-bg.jpg") center top no-repeat;
	height: 1024px;
	position: relative;
	&__top {
		display: flex;
		align-items: center;
		padding: 32px 0;
	}

	&__logo {
		display: inline-flex;
		align-items: center;
	}

	&__btns {
		display: flex;
		align-items: center;
		border-radius: 16px;
		border: 1px solid #67adaf;
		background: rgba(255, 255, 255, 0.06);
		box-shadow: -5px -3px 12px 0px rgba(255, 255, 255, 0.11) inset;
		backdrop-filter: blur(5px);
		padding: 8px;
	}

	&__text {
		position: relative;
		p {
			color: #f8ead1;
		}
		padding: 15px 0 15px 40px;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			width: 10px;
			border-radius: 10px 0 0 10px;
			border: 0.5px solid #9a7850;
			border-right: 0;
		}
	}

	&__hero {
		height: 892px;
	}

	&__left {
		padding-top: 100px;
		padding-bottom: 60px;
		box-sizing: border-box;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__arrow {
		left: 50%;
		transform: translate(-50%, 0);
		margin-left: 201px;
	}

	&__letters {
		position: absolute;
		overflow: hidden;
		left: 50%;
		top: 0;
		margin-left: 337px;
		height: 100%;
		transform: translate(-50%, 0);
	}
	&__breadcrumb {
		height: 103px;
		display: flex;
		align-items: center;
	}

	.collage {
		&__geometry {
			position: absolute;
			left: 50%;
			top: 35px;
			transform: translate(-50%, 0);
			&--1 {
				margin-left: -903px;
			}
			&--2 {
				margin-left: 945px;
				margin-top: 366px;
			}
			&--3 {
				margin-left: 908px;
				margin-top: 77px;
			}
		}
		&__coin {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			&--1 {
				height: 254px;
				margin-left: 360px;
				top: 496px;
				width: 250px;
				img {
					z-index: 1;
					position: relative;
				}

				#lottie-container {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					height: 800px;
					width: 800px;
					margin-top: -50px;
				}

				&:before {
					content: "";
					display: block;
					z-index: 0;
					position: absolute;
					width: 356px;
					height: 153px;
					left: -58px;
					top: 97px;
					transform: rotate(43deg);
					border-radius: 50%;
					border: 3px solid rgba(255, 255, 255, 0.09);
					display: none;
				}
				&:after {
					content: "";
					display: block;
					z-index: 0;
					position: absolute;
					width: 364px;
					height: 161px;
					left: -62px;
					top: 93px;
					transform: rotate(43deg);
					border-radius: 50%;
					border: 1px solid rgba(255, 255, 255, 0.07);
					display: none;
				}
			}
			&--2 {
				margin-left: 412px;
				top: 293px;
			}
		}

		&__box {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			margin-left: 423px;
			top: 293px;
		}

		&__cube {
			position: absolute;
			left: 50%;
			margin-left: 204px;
			top: 500px;
			transform: translate(-50%, 0);
			svg {
				.svg-path-1 {
					@include anim("myAnimation 3s linear 0s infinite normal");
				}
				.svg-path-2 {
					@include anim("myAnimation 3s linear 1s infinite normal");
				}
				.svg-path-3 {
					@include anim("myAnimation 3s linear 1.5s infinite normal");
				}
				.svg-path-4 {
					@include anim("myAnimation 3s linear 2.5s infinite normal");
				}
			}
		}
	}

	.details {
		&__img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			mix-blend-mode: luminosity;
			svg {
				position: absolute;
			}
			img {
				position: relative;
				z-index: 1;
			}
			&:before {
				content: "";
				display: block;
				z-index: 0;
				height: 100%;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0.3;
				border-radius: 50%;
				position: absolute;
				@include border-gradient(#fff, rgba(#fff, 0) 70%);
			}
			&:after {
				content: "";
				display: block;
				z-index: 0;
				position: absolute;
				opacity: 0.06;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				height: 75%;
				width: 75%;
				position: absolute;
				background: linear-gradient(#fff, rgba(255, 255, 255, 0) 70%);
			}
			&--1 {
				height: 78px;
				width: 78px;
				margin-top: -268px;
				margin-left: 58px;
				svg {
					left: 50%;
					top: 100%;
					margin-top: 5px;
				}
				&:before {
					transform: rotate(90deg);
				}
			}

			&--2 {
				width: 52px;
				height: 52px;
				margin-left: -108px;
				margin-top: 287px;
				svg {
					left: 100%;
					margin-left: 5px;
					bottom: 50%;
				}
				&:before {
					transform: rotate(0deg);
				}
			}

			&--3 {
				width: 52px;
				height: 52px;
				margin-left: 295px;
				margin-top: -231px;
				svg {
					right: 100%;
					top: 50%;
					margin-right: 6px;
				}
				&:before {
					transform: rotate(0deg);
				}
			}

			&--4 {
				width: 79px;
				height: 79px;
				margin-left: 561px;
				margin-top: 194px;
				img {
					margin-top: 7px;
					margin-left: 2px;
				}
				svg {
					right: 100%;
					bottom: 50%;
					margin-right: 6px;
				}
				&:before {
					transform: rotate(0deg);
				}
			}
		}
	}
	&--default {
		background: url("../images/header-page-bg.jpg") center top no-repeat;
		height: 237px;
	}
	&--form {
		position: absolute;
		z-index: 11;
		left: 0;
		top: 0;
		right: 0;
		background: none;
		height: auto;
	}
}

.home-investment {
	padding: 80px 0 50px 0;
	background: url("../images/home-invest-bg-wide.jpg") center top no-repeat;
	position: relative;
	&__letters {
		position: absolute;
		overflow: hidden;
		left: 50%;
		top: 0;
		margin-left: -196px;
		height: 100%;
		transform: translate(-50%, 0);
	}
}

.plan-list {
	&__decription {
		margin-bottom: 10px;
		background: linear-gradient(to right, rgba(221, 233, 235, 0.4), rgba(221, 233, 235, 0));
		position: relative;
		padding: 20px 0 20px 20px;
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			height: 1px;
			width: 100%;
			left: 0px;
			top: 0;
			background: linear-gradient(to right, rgba(221, 233, 235, 1), rgba(221, 233, 235, 0));
		}
		&:after {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			height: 1px;
			width: 100%;
			left: 0px;
			bottom: 0;
			background: linear-gradient(to right, rgba(221, 233, 235, 1), rgba(221, 233, 235, 0));
		}
		> span {
			font-size: 23px;
			color: #a0b3b361;
			font-weight: 900;
			letter-spacing: -0.8px;
			line-height: 200%;
			font-family: $font1;
			position: absolute;
			height: 100%;
			text-align: center;
			right: 100%;
			margin-right: 1px;
			border: 1px solid #000;
			border: 1px solid rgba(221, 233, 235, 1);
			//border-right: 1px solid rgba(221, 233, 235, 1);
			top: 0;
			background: rgba(221, 233, 235, 0.4);
			border-radius: 13px 0 0 13px;
			writing-mode: vertical-rl;
			width: 48px;
			b {
				transform: scale(-1);
				position: absolute;
				margin-top: -10%;
			}
		}
		&--other {
			&:before,
			&:after {
				background: linear-gradient(to right, rgba(#efe9da, 1), rgba(#efe9da, 0));
			}
			> span {
				border-color: #efe9da;
				background: rgba(#f7f5ef, 0.4);
			}
			background: linear-gradient(to right, rgba(#f7f5ef, 0.4), rgba(#f7f5ef, 0));
		}
	}
	&__item {
		padding: 20px 27px;
		position: relative;
		background: #fff;
		border-radius: 10px;
		background:
			url("../images/plan-bg-1.png") left bottom no-repeat,
			linear-gradient(to bottom left, rgba(221, 233, 235, 0), rgba(221, 233, 235, 0.5)),
			#fff;
		box-shadow: 0 10px 25px -10px #bccfd385;
		&--yellow {
			background:
				url(../images/plan-bg-2.png) left bottom no-repeat,
				linear-gradient(to bottom left, rgb(255 142 43 / 0%), rgb(255 142 44 / 4%)),
				#fff;
		}
		&--deposit {
			padding: 0;
			background: none;
			box-shadow: none;
			.plan-list__name {
				color: $dark;
			}
			&:before,
			&:after {
				display: none !important;
			}
		}

		&--other {
			padding: 0;
			//box-shadow: none;
			//background: none;
			&:before,
			&:after {
				display: none !important;
				//height: 10px !important;
			}
			input {
				position: absolute;
				opacity: 0;
				&:checked {
					~ label {
						border: 1px solid rgba(74, 86, 94, 0.25);
						background: #fff;
						box-shadow: 0 10px 20px -15px #dae9ed;
						.plan-list__name {
							color: $dark;
						}
					}
				}
			}
			label {
				//border: 1px solid transparent;
				border: 1px solid rgba(74, 86, 94, 0.16);
				cursor: pointer;
				display: block;
				transition: all 0.2s ease-in-out;
				padding: 20px;
				border-radius: 10px;
			}
		}
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			border: 1px solid rgba(#4a565e, 0.16);
			height: 50%;
			border-radius: 10px 10px 0 0;
			border-bottom: none;
			width: 100%;
			left: 0;
			top: 0;
		}
		&:after {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			border: 1px solid rgba(#4a565e, 0.16);
			height: 50%;
			border-radius: 0 0 10px 10px;
			border-top: none;
			width: 100%;
			left: 0;
			bottom: 0;
		}
	}

	&__collapse {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 2;
	}
	&__btn {
		position: relative;
		z-index: 1;
	}
	&__calc {
		backdrop-filter: blur(13.5px);
		background: rgb(246 247 249 / 79%);
		border-radius: 10px;
		padding: 22px 27px;
		height: 335px;
		border: 1px solid #e0e1e1;
		padding: 22px 27px;

		.dhs-block {
			position: absolute;
			right: 42px;
			top: 26px;
			z-index: 10;
			display: flex;
			align-items: center;
			&--yellow {
				b {
					background-image: linear-gradient(265deg, #ffd640, #ff882a);
				}
			}
			abbr {
				margin-right: 10px;
				color: #e1e4eb;
				font-size: 22px;
				display: inline-flex;
				align-items: center;
				margin-top: -6px;
			}
			b {
				-webkit-text-fill-color: transparent;
				background-image: linear-gradient(269deg, #2d6979, #3c8689);
				background-clip: text;
				-webkit-background-clip: text;
				font-size: 22px;
				font-weight: 900;
				letter-spacing: -0.8px;
				line-height: 90.2%;
				font-family: $font1;
			}
			span {
				align-items: center;
				border: 1px solid rgba(43, 96, 114, 0.13);
				border-radius: 19px;
				color: #9eb6be;
				display: inline-flex;
				font-size: 13px;
				font-weight: 700;
				font-family: $font1;
				text-transform: uppercase;
				height: 18px;
				letter-spacing: -0.77px;
				margin-left: 5px;
				padding: 0 6px;
			}
		}

		.media__label {
			color: #929292;
			display: block;
			font-family: $font1;
			font-size: 9px;
			font-weight: 400;
			letter-spacing: 0.45em;
			margin-bottom: 10px;
			text-transform: uppercase;
			white-space: nowrap;
		}
		.media__value {
			color: #000;
			font-family: $font;
			font-size: 21px;
			font-weight: 700;
			letter-spacing: -0.84px;
			line-height: 80.2%;
			white-space: nowrap;
		}
	}
	&__more {
		position: absolute;
		font-size: 18px;
		right: 15px;
		top: 15px;
		color: #adb3b6;
		height: 30px;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;
		i.icon-more {
			display: none;
		}
		i.icon-close {
			display: block;
			font-size: 14px;
		}
		&.collapsed {
			i.icon-more {
				display: block;
			}
			i.icon-close {
				display: none;
			}
			z-index: 1;
		}
	}
	&__name {
		display: block;
		font-size: 18px;
		padding-bottom: 11px;
		font-weight: 600;
		//color: #9ba0a7;
		color: $dark;
		border-bottom: 1px solid #d2d5d79e;
		margin-bottom: 15px;
		font-family: $font1;
		transition: all 0.4s ease-in-out;
	}
	&__dhs {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		font-family: $font1;
		b {
			font-size: 32px;
			font-weight: 900;
			line-height: 90.2%;
			letter-spacing: -0.8px;
			background: linear-gradient(269deg, #2d6979 0%, #3c8689 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		span {
			margin-left: 5px;
			color: #9eb6be;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: -0.77px;
			padding: 0px 8px;
			display: inline-flex;
			align-items: center;
			height: 18px;
			border-radius: 19px;
			border: 1px solid rgba(43, 96, 114, 0.13);
		}
		&--yellow {
			b {
				background-image: linear-gradient(265deg, #ffd640 0%, #ff882a 100%);
			}
			span {
				color: #a9aaab;
			}
		}
	}

	&__limits {
		span {
			display: block;
			color: $text;
			font-family: $font1;
			font-size: 9px;
			font-weight: 400;
			letter-spacing: 0.45em;
			text-transform: uppercase;
			white-space: nowrap;
		}
		> abbr {
			margin: 0 5px;
			width: 100%;
			background: url("../images/svg/line-devider.svg") center center repeat-x;
			height: 4px;
		}
		b {
			white-space: nowrap;
			color: #000;
			font-family: $font;
			font-size: 21px;
			font-weight: 700;
			line-height: 80.2%;
			letter-spacing: -0.84px;
			abbr {
				font-weight: 300;
				color: $text;
			}
			small {
				font-size: 80%;
			}
			&.small {
				font-size: 19px;
			}
		}
	}
}

.home-about {
	padding: 96px 0 0 0;
	background: url("../images/home-about-bg.jpg") center top no-repeat;
	position: relative;
	&__letters {
		position: absolute;
		height: auto;
		left: 50%;
		top: 1003px;
		transform: translate(-50%, 0);
	}
	.details {
		&__img {
			&--1 {
				margin-left: -563px;
				margin-top: 259px;
			}
			&--2 {
				margin-left: 503px;
				margin-top: 887px;
			}
			&--3 {
				margin-left: 572px;
				margin-top: 187px;
			}
			&--4 {
				margin-left: -135px;
				margin-top: 862px;
			}
		}
	}

	.info {
		position: relative;
		background: url("../images/svg/line-devider-wide-vertical.svg") center top no-repeat;
		&__icon {
			bottom: -9px;
			color: #d7dcdc;
			position: absolute;
			right: -13px;
			font-size: 24px;
		}
		&__devider {
			position: absolute;
			left: 50%;
			top: 189px;
			transform: translate(-50%, 0);
		}
		&__item {
			padding: 45px 60px;
			height: 100%;
			display: flex;
			align-items: center;
			position: relative;
			> div {
			}
			p {
				a {
					text-decoration: underline;
				}
			}
			b {
				color: $dark;
				display: flex;
				align-items: center;
				font-size: 84px;
				font-style: normal;
				font-weight: 700;
				line-height: 1;
				letter-spacing: -2.94px;
				small {
					font-size: 21px;
					font-style: normal;
					font-weight: 500;
					letter-spacing: 0;
					margin: -37px 0 0 6px;
					line-height: 119.2%;
				}
			}
			span {
				color: #878787;
				font-size: 21px;
				font-weight: 500;
				margin-top: -5px;
				display: block;
			}
		}
	}
	.globe {
		$self: &;
		height: 695px;
		overflow: hidden;
		z-index: 10;
		position: relative;
		&__item {
			z-index: 11;
			position: absolute;
			left: 0;
			top: 0;
			height: 93px;
			> img {
				position: absolute;
				left: 0;
				top: 0;
			}
			&--1 {
				left: 313px;
				top: 10px;
				width: 222px;
				.globe__line {
					bottom: -46px;
					right: -44px;
					z-index: -1;
				}
				.globe__point {
					bottom: -27px;
					right: -24px;
				}
				.globe__flag {
					left: 0;
					top: 0;
				}
			}

			&--2 {
				left: 661px;
				top: 99px;
				width: 222px;
				> img {
					left: -38px;
					top: -39px;
				}
				.globe__line {
					top: -49px;
					left: -45px;
					z-index: -1;
				}
				.globe__point {
					left: -25px;
					top: -27px;
				}
				.globe__flag {
					right: 0;
					top: 0;
				}
			}

			&--3 {
				left: 140px;
				top: 176px;
				width: 281px;
				.globe__line {
					bottom: -46px;
					right: -44px;
					z-index: -1;
				}
				.globe__point {
					bottom: -27px;
					right: -24px;
				}
				.globe__flag {
					left: 0;
					top: 0;
				}
			}

			&--4 {
				left: 561px;
				top: 225px;
				width: 222px;
				> img {
					left: -38px;
					top: -39px;
				}
				.globe__line {
					top: -49px;
					left: -45px;
					z-index: -1;
				}
				.globe__point {
					left: -25px;
					top: -27px;
				}
				.globe__flag {
					right: 0;
					top: 0;
				}
			}

			&--5 {
				left: 665px;
				top: 490px;
				width: 222px;
				.globe__line {
					bottom: -46px;
					right: -44px;
					z-index: -1;
				}
				.globe__point {
					bottom: -27px;
					right: -24px;
				}
				.globe__flag {
					left: 0;
					top: 0;
				}
			}

			&:hover {
				svg {
					path.path-1 {
						fill: #cb9119;
					}
					stop:first-child {
						stop-color: #ffda41;
					}
					stop:last-child {
						stop-color: #fbae33;
					}
				}
				.globe__line {
					@include anim("rotate 5s linear 0s infinite normal");
				}
				.globe__flag {
					opacity: 1;
				}
				.globe__point {
					&:after {
						opacity: 1;
					}
				}
			}
		}

		&__content {
			left: 139px;
			position: absolute;
			top: 452px;
			width: 360px;
		}

		&__text {
			z-index: 10;
			position: relative;
			align-items: center;
			padding-left: 25px;
			height: 100%;
			span {
				color: #cbd2db;
				font-family: $font1;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				display: block;
				letter-spacing: 2.625px;
			}
			b {
				color: #fff;
				font-family: $font;
				font-size: 28px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: -1.54px;
				small {
					font-size: 80%;
				}
			}
			display: flex;
		}

		&__flag {
			position: absolute;
			opacity: 0.3;
			z-index: 1;
			transition: all 0.2s ease-in-out;
		}

		&__point {
			height: 30px;
			width: 30px;
			position: absolute;
			border-radius: 50%;
			z-index: 10;
			&:after {
				content: "";
				display: block;
				z-index: 0;
				height: 100%;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				opacity: 0;
				transition: all 0.2s ease-in-out;
				border-radius: 50%;
				background: linear-gradient(180deg, #ffda41 0%, #fbae33 100%);
				position: absolute;
			}
			&:before {
				content: "";
				display: block;
				z-index: 0;
				height: 18px;
				width: 18px;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				z-index: 2;
				background: #fff;
				transform: translate(-50%, -50%);
				position: absolute;
			}
			background: linear-gradient(180deg, #4fd9e4 0%, #31a9b1 100%);
		}

		&__line {
			position: absolute;
		}

		#globe {
			opacity: 0.2;
			margin-top: 45px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 1200px;
			width: 1200px;
			position: absolute;
		}
	}
}

.home-work {
	position: relative;
	height: 882px;
	background: url("../images/home-work-bg.jpg") center top no-repeat;
	.details {
		&__img {
			&--1 {
				margin-left: -673px;
				margin-top: 167px;
			}
			&--2 {
				margin-left: 503px;
				margin-top: 887px;
			}
			&--3 {
				margin-left: 43px;
				margin-top: 398px;
			}
			&--4 {
				margin-left: 181px;
				margin-top: 589px;
			}
		}
	}
	&__letters {
		position: absolute;
		overflow: hidden;
		left: 50%;
		top: 0;
		margin-left: -102px;
		height: 100%;
		transform: translate(-50%, 0);
	}
	.collage {
		margin-top: 70px;
		height: 573px;
		position: relative;
		&__text {
			position: absolute;
			width: 167px;
			.round {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				position: absolute;
				display: block;
				left: -6px;
				top: 0;
				&:after {
					left: 50%;
					top: 100%;
					margin-top: -7px;
					margin-left: -1px;
					width: 1px;
					height: 30.5px;
					content: "";
					display: block;
					z-index: 0;
					background: linear-gradient(to bottom, #cdd4d4, rgba(#cdd4d4, 0));
					position: absolute;
				}
				&:before {
					content: "";
					display: block;
					z-index: 0;
					position: absolute;
					width: 11px;
					height: 11px;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
				}
				&--green {
					background: rgba($green, 0.15);
					&:before {
						background: $green;
					}
				}
				&--red {
					background: rgba($red, 0.15);
					&:before {
						background: $red;
					}
				}
				&--yellow {
					background: rgba($yellow, 0.15);
					&:before {
						background: $yellow;
					}
				}
			}
			abbr {
				color: #dae2e2;
				font-family: $font1;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 97.2%;
				letter-spacing: -0.21px;
				margin-bottom: 7px;
				display: block;
			}
			p {
				color: #878787;
				font-size: 18px;
				line-height: 97.2%;
				letter-spacing: -0.27px;
				b {
					color: #060606;
					font-weight: 500;
				}
			}
			&--1 {
				left: 386px;
				top: 421px;
				padding-top: 48px;
			}
			&--2 {
				left: 892px;
				padding-top: 0;
				top: 42px;
				i.round {
					left: -78px;
					top: 82px;
					&:after {
						left: 29px;
						top: -11px;
						transform: rotate(228deg);
					}
				}
			}
			&--3 {
				left: 796px;
				top: 421px;
				padding-top: 48px;
			}
			&--4 {
				left: 348px;
				padding-top: 0;
				top: 5px;
				i.round {
					left: 34px;
					top: 115px;
					&:after {
						left: 13px;
						top: -17px;
						margin-left: -1.5px;
						transform: scale(-1);
					}
				}
			}
			&--5 {
				left: 34px;
				padding-top: 0;
				top: 235px;
				i.round {
					left: 192px;
					top: 37px;
					&:after {
						left: -9px;
						top: 4px;
						transform: rotate(90deg);
					}
				}
			}
		}

		&__box {
			position: absolute;
			&--1 {
				left: 163px;
				top: 160px;
			}
			&--2 {
				left: 163px;
				top: 360px;
			}
			&--3 {
				left: 923px;
				top: 255px;
			}
		}
		&__line {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 0;
			margin-top: -2px;
			> span {
				height: 30px;
				width: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				background: $green;
				border-radius: 50%;
				font-size: 7px;
				margin: 16.5px;
				position: absolute;
				offset-path: path(
					"M359 164.5L403.293 241.621C414.885 234.898 431.005 237.095 439.401 247.539C467.08 281.973 509.476 304 557 304C640.395 304 708 236.171 708 152.5C708 68.8289 640.395 1 557 1C509.212 1 466.609 23.2725 438.941 58.0355C430.596 68.5202 414.487 70.796 402.862 64.1305L304.628 242.148C293.165 235.661 277.455 237.862 269.155 248.089C241.468 282.207 199.27 304 152 304C68.605 304 1 236.171 1 152.5C1 68.8289 68.605 1 152 1C199.537 1 241.943 23.0393 269.622 57.4893C277.872 67.7567 293.57 70.0347 305.065 63.6055L344 139.5"
				);
				offset-distance: 70%;
				offset-rotate: auto 90deg;
				@include anim("animLine 20s linear 0s infinite normal");
			}
		}
		&__logo {
			z-index: 1;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			width: 216px;
			height: 216px;
			background: linear-gradient(351deg, #3e888b 0%, #31a9b1 100%);
			box-shadow: 0px 1px 0px 0px #81cada inset;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			> div {
				border-radius: 50%;
				width: 200px;
				background: #fff;
				height: 200px;
				box-shadow: 0px 3px 7px #276370;
				text-align: center;
				padding: 37px 0;
			}
		}
	}
}

.home-advantages {
	height: 1361px;
	position: relative;
	background: url("../images/home-adv-bg.jpg") center top no-repeat;

	.details {
		&__img {
			&--1 {
				margin-left: -163px;
				margin-top: 515px;
			}
			&--2 {
				margin-left: 503px;
				margin-top: 887px;
			}
			&--3 {
				margin-left: 491px;
				margin-top: 891px;
			}
			&--4 {
				margin-left: -533px;
				margin-top: 977px;
			}
			&--5 {
				margin-left: 423px;
				margin-top: 510px;
				transform: rotate(30deg);
				mix-blend-mode: normal;
			}
		}
	}
	&__content {
		p {
			&.s26 {
				color: #fff;
			}
			&.s19 {
				color: #fff3e8;
				b {
					color: #fff;
				}
			}
		}
	}
	&__top {
		height: 314px;
		padding-top: 94px;
		padding-left: 28px;
		padding-right: 28px;
	}

	&__img {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		z-index: 2;
		margin-top: 246px;
		margin-left: -10px;
	}

	&__letters {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		z-index: 1;
		margin-top: 141px;
	}

	&__list {
		position: relative;
		height: 868px;
		z-index: 11;
	}

	&__item {
		width: 272px;
		height: 282px;
		position: absolute;

		&--1 {
			left: 28px;
			top: 135px;
		}
		&--2 {
			left: 360px;
			top: 60px;
		}
		&--3 {
			right: 28px;
			top: 169px;
		}
		&--4 {
			left: 28px;
			top: 493px;
		}
		&--5 {
			left: 360px;
			top: 574px;
			.home-advantages__icon {
				top: 176px;
				left: -22px;
			}
			.home-advantages__arrow {
				right: 16px;
			}
			.home-advantages__inset {
				padding-top: 35px;
				padding-right: 30px;
				clip-path: url(#svgBox6);
			}
		}
		&--6 {
			right: 28px;
			top: 532px;
		}
	}

	&__inset {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: linear-gradient(204deg, rgb(28 52 69 / 51%), rgb(35 46 64 / 81%));
		clip-path: url(#svgBox5);
		backdrop-filter: blur(13.5px);
		padding: 72px 48px;
	}

	&__icon {
		width: 62px;
		height: 62px;
		position: relative;
		left: 50px;
		top: -21px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: linear-gradient(204deg, rgb(28 52 69 / 51%), rgb(35 46 64 / 81%));
		backdrop-filter: blur(13.5px);
		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__text {
		color: #c1c1c1;
		b {
			color: #fff;
		}
	}

	&__arrow {
		position: absolute;
		right: 0;
		bottom: 0;
		color: #fff;
		height: 74px;
		width: 83px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__bottom {
		padding-left: 28px;
		padding-right: 28px;
		position: relative;
		z-index: 10;
		p {
			color: #fff;
			font-family: $font1;
			font-size: 19px;
			font-style: normal;
			font-weight: 700;
			line-height: 119.2%;
		}
	}
}

.home-certificate {
	position: relative;
	height: 1007px;
	background: url("../images/home-cert-bg.jpg") center top no-repeat;
	.details {
		&__img {
			&--3 {
				margin-left: -237px;
				margin-top: 249px;
			}
			&--4 {
				margin-left: -723px;
				margin-top: 674px;
			}
		}
	}

	&__collage {
		height: 100%;

		position: relative;
	}

	&__box {
		top: 580px;
		z-index: 1;
	}

	&__img {
		position: absolute;
		right: 12px;
		top: -24px;
	}
}

.home-partners {
	position: relative;
	height: 974px;
	background: url("../images/home-partners-bg.jpg") center top no-repeat;
	.details {
		&__img {
			&--1 {
				margin-left: -682px;
				margin-top: 237px;
			}
			&--3 {
				margin-left: 61px;
				margin-top: 349px;
			}
			&--4 {
				margin-left: 190px;
				margin-top: 630px;
			}
		}
	}
	.collage {
		height: 351px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 77px;
		position: relative;
		&__coin {
			position: absolute;
			left: 50%;
			top: 87px;
			margin-left: -315px;
			transform: translate(-50%, 0);
		}

		&__img {
			margin-left: -129px;
		}
		&__box {
			position: absolute;
			right: 22px;
			top: 48px;
			z-index: 10;
		}
	}
}

.footer {
	height: 644px;
	position: relative;
	background: url("../images/footer-bg.jpg") center top no-repeat;
	&__top {
		display: flex;
		align-items: center;
		height: 316px;
	}

	&__note {
		padding: 50px;
		b {
			color: #fff;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 119.2%;
			letter-spacing: -0.6px;
			display: block;
			margin-bottom: 10px;
		}
		p {
			color: #bec7ca;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 119.2%;

			&.copyright {
				color: #bebebe;
				margin-top: 99px;
				font-size: 14px;
			}
		}
	}
	&__arrow {
		left: 50%;
		transform: translate(-50%, 0) scale(-1);
		top: 0;
		&:before {
			opacity: 0;
		}
	}
	&__logo {
		left: -32px;
		position: absolute;
		top: 16px;
		height: 99px;
	}
}

.page {
	&.padding-top {
		padding-top: 86px;
	}
	&.padding-bottom {
		padding-bottom: 86px;
	}
	.container {
		position: relative;
		z-index: 10;
	}

	.info {
		position: relative;
		&__icon {
			bottom: -9px;
			color: #d7dcdc;
			position: absolute;
			right: -13px;
			font-size: 24px;
		}

		&__item {
			padding: 22px 0;
			height: 100%;
			display: flex;
			align-items: center;
			position: relative;
			b {
				color: $dark;
				display: flex;
				align-items: center;
				font-size: 84px;
				font-style: normal;
				font-weight: 700;
				line-height: 1;
				letter-spacing: -2.94px;
				small {
					font-size: 21px;
					font-style: normal;
					font-weight: 500;
					letter-spacing: 0;
					margin: -37px 0 0 6px;
					line-height: 119.2%;
				}
			}
			span {
				color: #878787;
				font-size: 21px;
				font-weight: 500;
				margin-top: -5px;
				display: block;
			}
		}
	}

	&--about {
		background: url("../images/page-about-bg.jpg") center top no-repeat;
		.collage {
			> svg {
				margin: 15px 0 0 15px;
			}
			&--2 {
				margin-left: -23px;
			}
			&__box {
				bottom: 74px;
				position: absolute;
				right: 110px;
				.box-blured__text {
					padding: 19px 54px 19px 27px;
					p {
						font-size: 25px;
						line-height: 1.1;
						color: #fff;
						letter-spacing: -0.06em;
						text-transform: none;
						font-family: $font;
						font-weight: 600;
					}
				}
			}
		}
	}

	&--globe {
		background: url("../images/page-about-bg-1.jpg") center top no-repeat;
		height: 1098px;
		position: relative;
		i.icon-star {
			position: absolute;
			color: #000;
			top: -14px;
			left: 50%;
			font-size: 28px;
			color: #fff;
			transform: translate(-50%, 0);
		}

		.globe {
			$self: &;
			height: 870px;
			overflow: hidden;
			z-index: 10;
			position: relative;
			&__item {
				z-index: 11;
				position: absolute;
				left: 0;
				top: 0;
				height: 93px;
				> img {
					position: absolute;
					left: 0;
					top: 0;
				}
				&--1 {
					left: 0px;
					top: 0;
					width: 222px;
					.globe__line {
						bottom: -46px;
						right: -44px;
						z-index: -1;
					}
					.globe__point {
						bottom: -27px;
						right: -24px;
					}
					.globe__flag {
						left: 0;
						top: 0;
					}
				}

				&--2 {
					left: 341px;
					top: 76px;
					width: 222px;
					> img {
						left: -38px;
						top: -39px;
					}
					.globe__line {
						top: -49px;
						left: -45px;
						z-index: -1;
					}
					.globe__point {
						left: -25px;
						top: -27px;
					}
					.globe__flag {
						right: 0;
						top: 0;
					}
				}

				&--3 {
					left: 0px;
					top: 176px;
					width: 281px;
					.globe__line {
						bottom: -46px;
						right: -44px;
						z-index: -1;
					}
					.globe__point {
						bottom: -27px;
						right: -24px;
					}
					.globe__flag {
						left: 0;
						top: 0;
					}
				}

				&--4 {
					left: 352px;
					top: 225px;
					width: 222px;
					> img {
						left: -38px;
						top: -39px;
					}
					.globe__line {
						top: -49px;
						left: -45px;
						z-index: -1;
					}
					.globe__point {
						left: -25px;
						top: -27px;
					}
					.globe__flag {
						right: 0;
						top: 0;
					}
				}

				&--5 {
					left: 277px;
					top: 490px;
					width: 222px;
					.globe__line {
						bottom: -46px;
						right: -44px;
						z-index: -1;
					}
					.globe__point {
						bottom: -27px;
						right: -24px;
					}
					.globe__flag {
						left: 0;
						top: 0;
					}
				}

				&:hover {
					svg {
						path.path-1 {
							fill: #cb9119;
						}
						stop:first-child {
							stop-color: #ffda41;
						}
						stop:last-child {
							stop-color: #fbae33;
						}
					}
					.globe__line {
						@include anim("rotate 5s linear 0s infinite normal");
					}
					.globe__flag {
						opacity: 1;
					}
					.globe__point {
						&:after {
							opacity: 1;
						}
					}
				}
			}
			&__text {
				z-index: 10;
				position: relative;
				align-items: center;
				padding-left: 25px;
				height: 100%;
				span {
					color: #cbd2db;
					font-family: $font1;
					font-size: 15px;
					font-style: normal;
					font-weight: 700;
					display: block;
					letter-spacing: 2.625px;
				}
				b {
					color: #fff;
					font-family: $font;
					font-size: 28px;
					font-style: normal;
					font-weight: 700;
					letter-spacing: -1.54px;
					small {
						font-size: 80%;
					}
				}
				display: flex;
			}

			&__flag {
				position: absolute;
				opacity: 0.3;
				z-index: 1;
				transition: all 0.2s ease-in-out;
			}

			&__point {
				height: 30px;
				width: 30px;
				position: absolute;
				border-radius: 50%;
				z-index: 10;
				&:after {
					content: "";
					display: block;
					z-index: 0;
					height: 100%;
					z-index: 1;
					left: 0;
					top: 0;
					width: 100%;
					opacity: 0;
					transition: all 0.2s ease-in-out;
					border-radius: 50%;
					background: linear-gradient(180deg, #ffda41 0%, #fbae33 100%);
					position: absolute;
				}
				&:before {
					content: "";
					display: block;
					z-index: 0;
					height: 18px;
					width: 18px;
					border-radius: 50%;
					left: 50%;
					top: 50%;
					z-index: 2;
					background: #fff;
					transform: translate(-50%, -50%);
					position: absolute;
				}
				background: linear-gradient(180deg, #4fd9e4 0%, #31a9b1 100%);
			}

			&__line {
				position: absolute;
			}

			#globe {
				opacity: 0.2;
				margin-top: -65px;
				left: -204px;
				top: 50%;
				transform: translate(0, -50%);
				height: 1200px;
				width: 1200px;
				position: absolute;
			}
		}
	}

	&--advantages {
		border-radius: 15px;
		color: #fff;
		background: url("../images/page-about-bg-2.jpg") center top no-repeat;
		background-size: cover;
		.list {
			gap: 60px;
			max-width: 986px;
			margin: 0 auto;
			display: flex;
			align-items: stretch;
			flex-wrap: wrap;
			justify-content: center;
			.item {
				height: 366px;
				position: relative;
				width: 272px;
			}
			.icon {
				align-items: center;
				border-radius: 50%;
				display: flex;
				height: 62px;
				justify-content: center;
				left: 50px;
				position: relative;
				top: -21px;
				width: 62px;
				svg {
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.inset {
				padding: 71px 33px 50px 40px;
				border-radius: 15px;
				background: linear-gradient(336deg, rgb(49 138 161 / 16%) -5.96%, rgb(36 112 131 / 46%) 106.3%);
				backdrop-filter: blur(13.5px);
				width: calc(33.33% - 40px);
				clip-path: url(#svgBox9);
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
			.overlay {
				border-radius: 20px 0 0 0;
				height: 300px;
				left: 0;
				opacity: 0.6;
				overflow: hidden;
				position: absolute;
				top: 0;
				width: 300px;
				&:before {
					background: #fdca3c;
					content: "";
					display: block;
					filter: blur(76px);
					height: 140px;
					left: -100px;
					position: absolute;
					top: -100px;
					width: 140px;
					z-index: 0;
				}
				&--green {
					&:before {
						background: $green;
					}
				}
				&--red {
					&:before {
						background: $red;
					}
				}
			}
			.text {
				b {
					display: block;
					margin-bottom: 15px;
					font-weight: 500;
					font-size: 21px;
				}
				p {
					font-size: 18px;
					color: rgba(194, 231, 242, 0.69);
				}
			}
		}
		.bottom {
			p {
				color: #c2e7f2;
				b {
					color: #fff;
				}
			}
		}
	}
	&--faq {
		background:
			linear-gradient(to bottom, rgba(#fff, 0), rgba(#fff, 1) 30%),
			url(../images/home-invest-bg.jpg) top no-repeat;
		padding: 40px 0;
		position: relative;
	}

	&--tutorial {
		background: url("../images/page-about-bg.jpg") center top no-repeat;
		background-size: cover;
		.collage {
			> svg {
				margin: 15px 0 0 15px;
			}
			&--2 {
				margin-left: -23px;
			}
			&__box {
				bottom: 74px;
				position: absolute;
				right: 110px;
				.box-blured__text {
					padding: 19px 54px 19px 27px;
					p {
						font-size: 25px;
						line-height: 1.1;
						color: #fff;
						letter-spacing: -0.06em;
						text-transform: none;
						font-family: $font;
						font-weight: 600;
					}
				}
			}
		}
	}

	.faq-list {
		li {
			border-bottom: 1px solid #f3f4f5;
			margin-bottom: 40px;
			padding-bottom: 40px;
			display: block;
			&.hide {
				display: none;
			}
			&:last-child {
				border: none;
			}

			.q {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				b {
					line-height: 115.2%;
					font-size: 26px;
					font-weight: 600;
					color: $dark;
					display: block;
				}
				i {
					flex-shrink: 0;
					width: 36px;
					margin-right: 10px;
					font-size: 25px;
					color: #dbe3e5;
					margin-top: 5px;
					transition: all 0.2s ease-in-out;
				}
			}

			.a {
				padding-left: 46px;
			}
			&:hover {
				.q {
					i {
						color: $green;
					}
				}
			}
		}
	}

	.collage {
		position: relative;
		svg {
			position: absolute;
		}
	}

	.box-gradient {
		padding: 50px 0 50px 70px;
		position: relative;
		p {
			position: relative;
			z-index: 10;
		}
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			border-radius: 25px 0 0 25px;
			width: 1000px;
			background: linear-gradient(to bottom left, rgba(#dde9eb, 0), rgba(#dde9eb, 0.5));
		}
	}

	.bounty {
		&__item {
			margin-bottom: 25px;
			border-radius: 15px;
			position: relative;
			border: 1px solid #fff;
			padding: 55px 40px;
			background:
				url("../images/grid-overlay.png") center -400px no-repeat,
				url("../images/bounty-ref-bg.jpg") left top no-repeat;
			background-size: cover;
			overflow: hidden;
			box-shadow: 0 10px 25px -15px #fff;
			&--default {
				border: 1px solid #f5f5f5;
				box-shadow: none;
				background:
					url("../images/grid-overlay.png") center -400px no-repeat,
					#fff;
				.bounty__list {
					li {
						i {
							//color: #a2a9bf;
							//background: $fb-gradient;
						}
					}
				}
			}
		}
		&__arrow {
			position: absolute;
			left: 50px;
		}
		&__img {
			&--1 {
				height: 389px;
				left: -117px;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
			}
			&--2 {
				left: 19px;
				margin-top: 120px;
				position: absolute;
				top: 50%;
				transform: rotate(-135deg) translate(0, -50%);
				height: 150px;
			}
			&--3 {
				height: 126px;
				left: 19px;
				margin-top: 120px;
				position: absolute;
				top: 50%;
				transform: rotate(-135deg) translate(0, -50%);
				filter: blur(172px);
				height: 294px;
			}

			&--4 {
				height: 389px;
				right: -117px;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
			}
			&--5 {
				height: 123px;
				left: 19px;
				margin-top: -30px;
				position: absolute;
				top: 0;
				transform: rotate(-45deg) translate(0, 0);
			}
			&--6 {
				right: -100px;
				margin-top: 120px;
				position: absolute;
				top: 50%;
				transform: rotate(-135deg) translate(0, -50%);
				filter: blur(172px);
				height: 294px;
			}
		}
		&__list {
			li {
				i {
					position: relative;
					height: 50px;
					width: 50px;
					border-radius: 50%;
					flex-shrink: 0;
					margin-right: 15px;
					color: $green;
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
					&:before {
						position: relative;
						z-index: 2;
					}
					&:after {
						z-index: 1;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						height: 36px;
						width: 36px;
						background: #fff;
						border-radius: 50%;
						content: "";
						display: block;
						z-index: 0;
						position: absolute;
					}

					background: linear-gradient(180deg, #4fd9e4, #31a9b1);
				}
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}
		}
		&__bonus {
			display: inline-flex;
			align-items: center;
			background: #fff;
			border: 1px solid #e6ebed;
			border-radius: 30px;
			padding: 12px 20px;
			height: 53px;
			font-weight: 400;
			font-size: 21px;
			color: $text;
			box-shadow: 0 5px 15px -10px #61798196;
			b {
				margin-left: 10px;
				color: $green;
				font-weight: 600;
			}
		}
		&__icon {
			display: inline-flex;
			align-items: center;
			background: #fff;
			border: 1px solid #000;
			border-radius: 30px;
			padding: 12px 20px;
			height: 53px;
			i {
				margin-right: 10px;
			}
			&--facebook {
				color: #475993;
				border-color: rgba(#475993, 0.5);
			}
			&--twitter {
				color: #03a9f4;
				border-color: rgba(#03a9f4, 0.5);
			}
			&--vk {
				color: #4b729f;
				border-color: rgba(#4b729f, 0.5);
				i {
					font-size: 18px;
				}
			}
		}
	}

	.tutorial {
		&__img {
			height: 413px;
			width: 472px;
			position: relative;
			&--left {
				clip-path: path(
					"M402.701 22C402.701 9.84973 392.851 0 380.701 0H22C9.84974 0 0 9.84972 0 22V389.377C0 401.527 9.8497 411.377 22 411.377H380.701C392.851 411.377 402.701 401.527 402.701 389.377V293.356C402.701 287.587 404.967 282.049 409.011 277.935L464.557 221.422C472.971 212.862 472.971 199.138 464.557 190.578L409.011 134.065C404.967 129.951 402.701 124.413 402.701 118.644V22Z"
				);
			}
			&--right {
				clip-path: path(
					"M68.1664 22C68.1664 9.84973 78.0161 0 90.1664 0H448.867C461.017 0 470.867 9.84972 470.867 22V389.377C470.867 401.527 461.017 411.377 448.867 411.377H90.1664C78.0162 411.377 68.1664 401.527 68.1664 389.377V293.356C68.1664 287.587 65.9004 282.049 61.8564 277.935L6.30994 221.422C-2.10348 212.862 -2.10351 199.138 6.30991 190.578L61.8564 134.065C65.9004 129.951 68.1664 124.413 68.1664 118.644V22Z"
				);
			}
			img {
				transition: all 0.4s ease-in-out;
				&.img-1 {
					height: 481px;
					margin-left: -90px;
					margin-top: 0;
					&:hover {
						height: 587px;
						margin-left: -180px;
					}
				}
				&.img-2 {
					margin-left: -80px;
					margin-top: 0;
					height: 536px;
					&:hover {
						height: 600px;
						margin-top: -180px;
					}
				}
				&.img-3 {
					height: 451px;
					margin-left: -90px;
					margin-top: 0;
					&:hover {
						height: 587px;
						margin-top: -180px;
					}
				}
				&.img-4 {
					height: 451px;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					&:hover {
						height: 587px;
					}
				}
			}
			&:hover {
			}
		}
		&__number {
			-webkit-text-stroke: 1px #abb3b459;
			color: transparent;
			font-family: $font1;
			font-size: 142px;
			font-weight: 700;
			line-height: 0.95;
			margin-left: -13px;
			margin-bottom: -30px;
		}
	}
}

.form {
	background: url(../images/page-about-bg.jpg) center -300px no-repeat;
	min-height: 100vh;
	display: flex;
	position: relative;
	.header__letters {
		margin-left: -359px;
	}
	align-items: stretch;
	&__bg {
		position: relative;
		height: 100%;
		border-radius: 15px 0 0 15px;
		background: url("../images/header-form.jpg") center top no-repeat;
		background-size: cover;
		width: calc(100% + 397px);
	}
	&__map {
		height: 100%;
		min-height: 100vh;
		width: 100%;

		.mapboxgl-canvas {
			mix-blend-mode: color-burn;
		}
		.mapboxgl-marker {
			height: 100px;
			width: 100px;
			align-items: center;
			justify-content: center;
			display: flex;
			backdrop-filter: blur(13.5px);
			background: rgba(#fff, 0.1);
			border-radius: 50%;
			&:before {
				content: "";
				height: 62px;
				width: 62px;
				z-index: 10;
				background: url("../images/logo-icon-large.png") center top no-repeat;
				background-size: 200% auto;
				margin-top: -4px;
			}
		}

		.mapboxgl-ctrl-logo,
		.mapboxgl-ctrl-bottom-right {
			display: none;
		}
	}
	.copyright {
		color: #8f9b9f;
	}
	&__coin {
		position: absolute;
		height: 254px;
		left: -106px;
		top: 50%;
		transform: translate(0, -50%);
		width: 250px;
	}
	&__geometry {
		left: 50%;
		position: absolute;
		top: 35px;
		-webkit-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
		&--2 {
			margin-left: 310px;
			margin-top: 366px;
		}
		&--3 {
			margin-left: 298px;
			margin-top: 77px;
		}
	}
	&__main {
		padding-top: 150px;
	}
}

//------------------------------------

.redirect {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 0;
	background: #fff;
	z-index: 1001;
	&__loading {
		position: relative;
		font-size: 55px;
		letter-spacing: -0.05em;
		font-weight: bold;
		color: rgba($dark, 0.2);
		> div {
			padding: 0 3px;
		}
	}
	&__coin {
		height: 254px;

		margin: 0 auto;
		width: 250px;
	}
	&__overlay {
		top: 0;
		position: absolute;
		color: $dark;
		width: 0;
		overflow: hidden;
		white-space: nowrap;
	}
	&__text {
		padding-top: 20px;
		font-size: 26px;
		line-height: 119.2%;
		color: $text;
		text-align: center;
	}
}

.details {
	&__img {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, 0);
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		mix-blend-mode: luminosity;
		img {
			position: relative;
			z-index: 1;
		}
	}
}

.devider {
	min-height: 2px;
	position: relative;
	align-items: center;
	justify-content: center;
	display: flex;
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	&--right {
		img {
			left: 0;
			transform: translate(0, -50%);
		}
	}
	i {
		&.icon-star {
			font-size: 26px;
			color: #d7dcdc;
		}
		&.icon-arrow-bottom {
			width: 57px;
			position: relative;
			z-index: 10;
			color: #abb3b4;
			background: #fff;
			padding-top: 2px;
			height: 57px;
			border-radius: 50%;
			border: 1px solid #dfe3e3;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 9px;
		}
	}
}

.box-blured {
	$self: &;
	position: absolute;
	&__round {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: absolute;
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: #fff;
			z-index: 1;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			height: 160%;
			width: 160%;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			z-index: 0;
			transform: translate(-50%, -50%);
		}
		&.yellow {
			&:after {
				background: linear-gradient(180deg, #ffd440 0%, #fcb435 100%);
			}
		}
		&.green {
			&:after {
				background: linear-gradient(180deg, #4fd9e4 0%, #31a9b1 100%);
			}
		}
		&.red {
			&:after {
				background: linear-gradient(180deg, #ff6e40 0%, #fc4135 100%);
			}
		}
	}
	&__line {
		bottom: -13px;
		left: -13px;
		z-index: -1;
		position: absolute;
		height: 69px;
		width: 69px;
		@include anim("rotate 8s linear 0s infinite normal");
		&:before {
			content: "";
			display: block;
			z-index: 0;
			position: absolute;
			border-radius: 50%;
			width: 55px;
			height: 55px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: linear-gradient(144deg, #0e4552 0%, rgba(14, 69, 82, 0) 100%);
			opacity: 0.37;
		}
	}
	&__inset {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		backdrop-filter: blur(13.5px);
	}
	&__text {
		color: #f0ece2;
		font-family: $font1;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 97.2%;
		letter-spacing: 2.625px;
	}
	&--1 {
		height: 132px;
		width: 382px;
		#{ $self }__text {
			color: #fff;
			font-family: $font;
			font-size: 28px;
			font-style: normal;
			font-weight: 700;
			line-height: 90.2%;
			letter-spacing: -1.4px;
			padding: 21px 48px;
			b {
				background: linear-gradient(273deg, #84f9b9 0%, #45e3fb 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-family: $font1;
				font-size: 32px;
				font-weight: 900;
				line-height: 90.2%;
				letter-spacing: -0.8px;
			}
			abbr {
				margin-left: 5px;
				color: #327680;
				font-family: $font1;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				padding: 3px 8px;
				line-height: 79.2%;
				letter-spacing: -0.825px;
				border-radius: 19px;
				background: linear-gradient(137deg, #45e3fb 0.68%, #84f9ba 100%);
			}
		}
		#{ $self }__img {
			bottom: 17px;
			position: absolute;
			right: 21px;
			z-index: 1;
		}
		#{ $self }__round {
			bottom: 12px;
			left: 12px;
			z-index: 10;
			&:after {
				background: linear-gradient(144deg, #0e4552 0%, rgba(14, 69, 82, 0) 100%);
			}
		}
		#{ $self }__inset {
			padding: 0px 0 0 35px;
			background: linear-gradient(212deg, rgba(27, 54, 70, 0.32) 0%, rgba(57, 129, 134, 0.67) 100%);
			clip-path: url(#svgBox1);
		}
	}

	&--2 {
		height: 45px;
		width: 140px;
		#{ $self }__text {
			padding: 15px 0;
			text-align: center;
			width: 92px;
		}
		#{ $self }__line {
			left: auto;
			bottom: -13px;
			right: -13px;
			&:before {
				background: #8e7c53;
				opacity: 0.12;
			}
		}
		#{ $self }__round {
			bottom: 12px;
			right: 13px;
			z-index: 10;
		}
		#{ $self }__inset {
			padding: 0px;
			background: linear-gradient(139deg, rgba(108, 96, 55, 0.51) 0%, rgba(123, 86, 11, 0.71) 100%);
			clip-path: url(#svgBox2);
		}
	}
	&--3 {
		height: 45px;
		width: 140px;
		#{ $self }__text {
			padding: 15px 0;
			text-align: center;
			width: 92px;
			color: #d3ecec;
		}
		#{ $self }__line {
			left: auto;
			bottom: -13px;
			right: -13px;
			&:before {
				background: #0e4552;
				opacity: 0.12;
			}
		}
		#{ $self }__round {
			bottom: 13px;
			right: 13px;
			z-index: 10;
		}
		#{ $self }__inset {
			padding: 0px;
			background: linear-gradient(139deg, rgba(79, 126, 126, 0.9) 0%, rgba(54, 90, 92, 0.77) 100%);
			clip-path: url(#svgBox2);
		}
	}

	&--4 {
		height: 45px;
		width: 146px;
		#{ $self }__text {
			padding: 15px 0;
			text-align: center;
			width: 92px;
			color: #f9efed;
		}
		#{ $self }__line {
			bottom: -13px;
			left: -13px;
			&:before {
				background: #8e7c53;
				opacity: 0.12;
			}
		}
		#{ $self }__round {
			bottom: 12px;
			left: 13px;
			z-index: 10;
		}
		#{ $self }__inset {
			padding: 0px;
			padding-left: 53px;
			background: linear-gradient(141deg, rgba(58, 17, 14, 0.65) 0%, rgba(150, 56, 30, 0.69) 100%);
			clip-path: url(#svgBox3);
		}
	}

	&--5 {
		height: 127px;
		width: 324px;
		#{ $self }__text {
			padding: 20px 38px;
			line-height: 1.2;
			span {
				color: #cbd2db;
				display: block;
				font-family: $font1;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: 2.625px;
			}
			b {
				color: #fff;
				font-family: $font;
				font-size: 28px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: 0;
			}
		}
		#{ $self }__overlay {
			position: absolute;
			left: 0;
			top: 0;
			height: 93px;
			width: 100%;
		}
		#{ $self }__line {
			left: auto;
			bottom: -13px;
			right: -12px;
			&:before {
				background: #0e4552;
				opacity: 0.12;
			}
		}
		#{ $self }__round {
			bottom: 13px;
			right: 13px;
			z-index: 10;
		}
		#{ $self }__inset {
			padding: 0px;
			background: linear-gradient(203deg, rgba(28, 52, 69, 0.64) 0%, rgba(35, 45, 64, 0.81) 100%);
			clip-path: url(#svgBox7);
		}
	}

	&--6 {
		height: 137px;
		width: 323px;
		#{ $self }__text {
			color: #fff;
			font-size: 28px;
			font-style: normal;
			font-weight: 700;
			font-family: $font;
			line-height: 90.2%;
			letter-spacing: -1.4px;
			padding: 21px 33px;
			b {
				font-family: $font1;
				background: linear-gradient(180deg, #ffda41 0%, #fbae33 100%);
				background-clip: text;
				-webkit-background-clip: text;
				letter-spacing: -0.8px;
				font-weight: 900;
				font-size: 32px;
				-webkit-text-fill-color: transparent;
			}
		}
		#{ $self }__line {
			bottom: -13px;
			left: -13px;
		}
		#{ $self }__round {
			bottom: 12px;
			left: 12px;
			z-index: 10;
			&:after {
				background: linear-gradient(144deg, #0e4552 0%, rgba(14, 69, 82, 0) 100%);
			}
		}
		#{ $self }__inset {
			padding: 0px 0 0 35px;
			background: linear-gradient(343deg, rgba(16, 74, 77, 0.67) 0%, rgba(30, 133, 140, 0.67) 100%);

			clip-path: url(#svgBox8);
		}
	}
}

.arrow {
	width: 68px;
	height: 81px;
	position: absolute;
	cursor: pointer;
	text-align: center;
	z-index: 11;
	bottom: 0;
	color: #1e4351;
	&:hover {
		color: #1e4351;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 68px;
		height: 68px;
		border-radius: 67px;
		margin-top: -7px;
		background: linear-gradient(144deg, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0.02) 100%);
		backdrop-filter: blur(3.5px);
		z-index: -1;
	}
	i {
		width: 68px;
		height: 68px;
		margin-top: -7px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 1;
		font-size: 9px;
	}
	svg {
		position: absolute;
	}
}

.letters {
	font-family: $font1;
	font-weight: 900;
	line-height: 60.7%;
	font-size: 367px;
	&--1 {
		color: #fff;
		opacity: 0.09;
		mix-blend-mode: soft-light;
		.letters__overlay {
			@include anim("move-text-top 50s linear 0s infinite normal");
		}
	}
	&--2 {
		color: #d9d5ce40;
		opacity: 0.5;
		.letters__overlay {
			margin-top: -51px;
		}
	}
	&--3 {
		.letters__overlay {
			opacity: 0.69;
			background: linear-gradient(180deg, rgba(150, 197, 197, 0.15) 0%, rgba(150, 197, 197, 0) 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	&--4 {
		color: #e9efef;
		opacity: 0.3;
		.letters__overlay {
			margin-top: -51px;
		}
	}
	&--5 {
		.letters__overlay {
			background: linear-gradient(180deg, rgba(#fff, 0.15) 0%, rgba(#fff, 0) 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	&__overlay {
		display: flex;
		flex-direction: column;
	}
}

@import "components/fix";

@import "components/account";
@import "components/table";
@import "components/confirmation";
