.tutorial-nav {
	.nav-tabs {
		border-bottom: none;
		overflow-x: clip;
		position: relative;
		z-index: 100;
		padding: 4px;
		height: 72px;
		background: url("../images/svg/tabs-svg.svg") 0 0 no-repeat;
		.nav-link {
			border: none;
			border-radius: 0;

			display: inline-flex;
			align-items: center;
			position: relative;
			width: 100%;
			padding: 10px 25px;
			height: 63px;
			border-radius: 12px;
			text-align: left;
			background: rgba(#000, 0.04);
			i {
				margin-right: 10px;
				width: 20px;
				height: 20px;
				border-radius: 50%;

				flex-shrink: 0;
				position: relative;
				background: $green-gradient;
				&:before {
					content: "";
					display: block;
					z-index: 0;
					position: absolute;
					height: 12px;
					width: 12px;
					border-radius: 50%;
					left: 50%;
					background: #fff;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			span {
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				display: block;
				line-height: normal;
				font-family: $font;
				letter-spacing: -1.1px;
				line-height: 1.3;
				background: linear-gradient(270deg, #050505 78.11%, rgba(5, 5, 5, 0.27) 115.85%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			small {
				display: block;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				font-family: $font1;
				letter-spacing: 0.35em;
				position: relative;
				color: $text;
				text-transform: uppercase;
			}
		}
	}
	.nav-tabs .nav-link.active,
	.nav-tabs .nav-item.show .nav-link {
		border: none;
		background: none;
		i {
			background: $orange-gradient;
		}
	}
}

.balance-nav {
	.nav-tabs {
		border-radius: 15px 0 0 15px;
		border-bottom: none;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 100;
		.nav-link {
			border: none;
			border-radius: 0;
			width: 100%;
			display: flex;
			align-items: center;
			position: relative;
			width: 100%;
			cursor: pointer;
			padding: 15px 20px;
			text-align: left;
			&:before {
				content: "";
				display: block;
				z-index: 0;
				position: absolute;
				bottom: 0;
				left: 0;
				height: 1px;

				width: calc(100% - 16px);
				background: #e4e9eb;
			}

			i {
				height: 45px;
				width: 45px;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-right: 10px;
				position: relative;
			}
			img {
				height: 35px;
			}
			b {
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				display: block;
				line-height: normal;
				font-family: $font;
				letter-spacing: -1.1px;
				line-height: 1.3;
				background: linear-gradient(270deg, #050505 78.11%, rgba(5, 5, 5, 0.27) 115.85%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				padding-right: 2px;
			}
			span {
				display: block;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				font-family: $font1;
				letter-spacing: 0.35em;
				position: relative;
				color: $text;
				text-transform: uppercase;
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
			&.disabled {
				opacity: 0.5;
				filter: grayscale(100%);
			}
		}
	}
	.nav-tabs .nav-link.active,
	.nav-tabs .nav-item.show .nav-link {
		border: none;
		background: linear-gradient(to right, rgba(#fff, 0.5), rgba(#fff, 1));
		i {
			background: #e6e8eb;
		}
	}
}
