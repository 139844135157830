.navbar {
	display: flex;
	margin: 0;
	padding: 0;
	position: relative;
	flex-wrap: nowrap;
	align-items: center;
	ul.navbar-nav {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: row;
		position: relative;
		.nav-item {
			position: relative;
			display: block;
			padding: 0 19px;
			&:first-child {
				padding-left: 0;
			}
		}
		a.nav-link {
			letter-spacing: 0.375px;
			font-weight: 700;
			font-family: $font1;
			color: #fff;
			display: inline-flex;
			align-items: center;
			white-space: nowrap;
			font-size: 15px;
			i {
				margin-right: 8px;
				color: #f8dcb6;
			}
			&:hover {
			}
		}
		span.nav-highlight {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 100px;
			background-color: #fff; /* или любой другой цвет */
			transition:
				left 0.3s ease,
				width 0.3s ease;
		}
	}

	&--form {
		ul.navbar-nav {
			a.nav-link {
				&_dark {
					color: $dark;
				}
				i {
					color: #bbe9f3;
				}
			}
		}
	}
	&--dashboard {
		ul.navbar-nav {
			a.nav-link {
				&_dark {
					color: $dark;
				}
				i {
					color: #52ab95;
				}
			}
		}
	}
	&--footer {
		ul.navbar-nav {
			position: relative;
			z-index: 15;
			display: block;
			.nav-item {
				padding: 0;
			}
			a.nav-link {
				text-align: left;
				color: #fff;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.375px;
				text-decoration-color: rgba(#fff, 0.2);
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	&--account {
		ul.navbar-nav {
			display: block;
			.nav-item {
				padding: 7px 0;
				overflow: hidden;
				margin-bottom: 0px;
			}
			a.nav-link {
				padding: 20px 25px;
				display: flex;
				align-items: center;
				color: #fff;
				border-radius: 7px 0 0 7px;
				height: 60px;
				margin: 0;
				position: relative;
				background: rgba(#fff, 0);
				i {
					width: 30px;
					font-size: 20px;
					margin-right: 5px;
					color: rgba(#fff, 0.4);
					&.icon-withraw {
						font-size: 18px;
					}
				}
				svg {
					position: absolute;
					top: -7px;
					right: 0px;
					margin-right: -7px;
					bottom: -7px;
					transition: margin-right 0s;
				}

				&_active,
				&:hover:not(.nav-link_active) {
					background: #fff;
					color: #2b6e6d;
					svg {
						margin-right: 0;
						transition: margin-right 0.4s;
					}
					i {
						opacity: 1;
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-image: $green-gradient;
					}
				}
			}
		}
	}
}
